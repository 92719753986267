import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const FullButton = ({ title, action, link, grey, href }) => {
  return (
    <>
      {link ? (
        <WrapperLink grey={grey} className="flexCenter pointer animate">
          <Link className="font15 bold flexCenter" style={{ color: "#fff", width: "100%", height: "100%" }} to={link}>
            {title}
          </Link>
        </WrapperLink>
      ) : href ? (
        <WrapperLink grey={grey} className="flexCenter pointer animate">
          <Link className="font15 bold flexCenter" style={{ color: "#fff", width: "100%", height: "100%" }} to={{ pathname: href }} target="_blank">
            {title}
          </Link>
        </WrapperLink>
      ) : (
        <Wrapper grey={grey} className="flexCenter pointer animate" onClick={action ? () => action() : null}>
          <p className="font15 bold flexCenter" style={{ color: "#fff", width: "100%", height: "100%" }}>
            {title}
          </p>
        </Wrapper>
      )}
    </>
  );
};

export default FullButton;

const Wrapper = styled.button`
  background-color: ${(props) => (props.grey ? "#CED4DA" : "#f9a81a")};
  height: 45px;
  width: 100%;
  outline: none;
  border: 0px;
  border-radius: 0.5rem;
  :hover {
    background-color: ${(props) => (props.grey ? "#AFBFCE" : "#f58600")};
  }
`;
const WrapperLink = styled.div`
  background-color: ${(props) => (props.grey ? "#CED4DA" : "#f9a81a")};
  height: 45px;
  width: 100%;
  border-radius: 0.5rem;
  :hover {
    background-color: ${(props) => (props.grey ? "#AFBFCE" : "#f58600")};
  }
`;