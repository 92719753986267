import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={27.462} height={27.462} viewBox="0 0 27.462 27.462" {...props}>
      <g data-name="Group 1555">
        <g data-name="Group 1542">
          <path
            data-name="Path 1365"
            d="M11.723 23.009a.973.973 0 01-.652-1.005l.611-7.034H8.699a.973.973 0 01-.875-.545.972.972 0 01.1-1.026l6.651-8.621a.975.975 0 011.149-.3.975.975 0 01.59 1.031l-.73 5.368h3.177a.978.978 0 01.858.512.979.979 0 01-.043 1l-6.721 10.24a.974.974 0 01-.814.44.967.967 0 01-.32-.054zm-1.04-9.99h2.063a.98.98 0 01.719.316.982.982 0 01.253.743l-.369 4.242 3.608-5.5h-2.486a.971.971 0 01-.735-.334.974.974 0 01-.232-.772l.388-2.857-3.209 4.16z"
            fill="#fff"
          />
        </g>
        <g data-name="Group 1545">
          <g data-name="Group 1543">
            <path
              data-name="Path 1366"
              d="M12.756 2.664V.975A.975.975 0 0113.731 0a.975.975 0 01.975.975v1.689a.976.976 0 01-.975.975.976.976 0 01-.975-.975z"
              fill="#fff"
            />
          </g>
          <g data-name="Group 1544">
            <path
              data-name="Path 1367"
              d="M12.756 26.487v-1.689a.975.975 0 01.975-.975.975.975 0 01.975.975v1.689a.975.975 0 01-.975.974.975.975 0 01-.975-.974z"
              fill="#fff"
            />
          </g>
        </g>
        <g data-name="Group 1548">
          <g data-name="Group 1546">
            <path
              data-name="Path 1368"
              d="M24.798 14.706a.975.975 0 01-.975-.975.975.975 0 01.975-.975h1.69a.975.975 0 01.975.975.975.975 0 01-.975.975h-1.69z"
              fill="#fff"
            />
          </g>
          <g data-name="Group 1547">
            <path
              data-name="Path 1369"
              d="M.975 14.706A.975.975 0 010 13.731a.975.975 0 01.975-.975h1.689a.975.975 0 01.975.975.975.975 0 01-.975.975H.975z"
              fill="#fff"
            />
          </g>
        </g>
        <g data-name="Group 1551">
          <g data-name="Group 1549">
            <path
              data-name="Path 1370"
              d="M5.216 6.595L4.021 5.4a.975.975 0 010-1.379.974.974 0 011.379 0l1.195 1.194a.976.976 0 010 1.379.974.974 0 01-.69.285.973.973 0 01-.689-.285z"
              fill="#fff"
            />
          </g>
          <g data-name="Group 1550">
            <path
              data-name="Path 1371"
              d="M22.061 23.445l-1.194-1.195a.975.975 0 010-1.379.975.975 0 011.378 0l1.195 1.195a.975.975 0 010 1.379.971.971 0 01-.689.286.972.972 0 01-.69-.286z"
              fill="#fff"
            />
          </g>
        </g>
        <g data-name="Group 1554">
          <g data-name="Group 1552">
            <path
              data-name="Path 1372"
              d="M20.867 6.595a.976.976 0 010-1.379l1.195-1.194a.974.974 0 011.378 0 .975.975 0 010 1.379l-1.194 1.195a.973.973 0 01-.689.285.973.973 0 01-.689-.285z"
              fill="#fff"
            />
          </g>
          <g data-name="Group 1553">
            <path
              data-name="Path 1373"
              d="M4.021 23.445a.975.975 0 010-1.379l1.195-1.195a.976.976 0 011.379 0 .975.975 0 010 1.379L5.4 23.445a.972.972 0 01-.69.286.972.972 0 01-.69-.286z"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
