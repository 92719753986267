import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} {...props}>
      <path
        data-name="Path 1647"
        d="M25 0A25 25 0 000 25a25 25 0 0025 25 25 25 0 0025-25A25 25 0 0025 0zm15.173 35.078l-5.1 5.1L25 30.1 14.917 40.181l-5.1-5.1L19.903 25 9.822 14.918l5.1-5.1L24.999 19.9 35.078 9.822l5.1 5.1L30.095 25z"
        fill="#fc3a30"
      />
    </svg>
  );
}

export default SvgComponent;
