import React from "react";
import styled from "styled-components";
// Svg-s
import CheckmarkIcon from "../../assets/svg/checkmark";

const Checkbox = ({ action, checked, disabled }) => {
  return (
    <WrapperDiv className="flexNullCenter" onClick={action && !disabled ? () => action() : null}>
      <CheckboxDiv
        className="flexCenter animate pointer"
        style={{
          backgroundColor: checked ? "#F9A81A" : "transparent",
          border: checked ? "2px solid #F9A81A" : "2px solid #CED4DA",
          cursor: disabled ? "not-allowed" : "pointer",
        }}
      >
        {checked ? (
          <IconWrapperDiv className="flexCenter">
            <CheckmarkIcon width={10.828} height={8.364} color="#fff" />
          </IconWrapperDiv>
        ) : null}
      </CheckboxDiv>
    </WrapperDiv>
  );
};

const WrapperDiv = styled.div`
  min-height: 24px;
`;
const CheckboxDiv = styled.button`
  width: 24px;
  height: 24px;
  margin-right: 10px;
  position: relative;
  border-radius: 0.4rem;
  &:hover {
    border: 2px solid #f9981a !important;
  }
`;
const IconWrapperDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 20px;
  height: 20px;
`;
export default Checkbox;
