export const getData = {
  method: "GET",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};

export const postData = {
  method: "POST",
  headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
  },
};
