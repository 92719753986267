import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24.965} height={27.557} viewBox="0 0 24.965 27.557" {...props}>
      <g data-name="Group 1570">
        <g data-name="Group 1556">
          <path
            data-name="Path 1374"
            d="M4.344 15.194a.975.975 0 01-.687-.284.976.976 0 01-.285-.687V7.661a.978.978 0 01.285-.687.978.978 0 01.687-.284h16.278a.978.978 0 01.687.285.978.978 0 01.285.687v6.562a.977.977 0 01-.285.687.975.975 0 01-.687.284H4.344zm16.278-.971zm-15.307-.971h14.336v-4.62H5.315v4.62z"
            fill="#404a4f"
          />
        </g>
        <g data-name="Group 1560">
          <g data-name="Group 1557">
            <path
              data-name="Path 1375"
              d="M8.235 24.094v-3.589a.971.971 0 01.971-.971.972.972 0 01.971.971v3.589a.972.972 0 01-.971.971.971.971 0 01-.971-.971z"
              fill="#404a4f"
            />
          </g>
          <g data-name="Group 1558">
            <path
              data-name="Path 1376"
              d="M14.788 24.094v-3.589a.971.971 0 01.971-.971.971.971 0 01.971.971v3.589a.971.971 0 01-.971.971.971.971 0 01-.971-.971z"
              fill="#404a4f"
            />
          </g>
          <g data-name="Group 1559">
            <path
              data-name="Path 1377"
              d="M11.511 24.094v-3.589a.972.972 0 01.971-.971.971.971 0 01.971.971v3.589a.971.971 0 01-.971.971.972.972 0 01-.971-.971z"
              fill="#404a4f"
            />
          </g>
        </g>
        <g data-name="Group 1565">
          <g data-name="Group 1561">
            <path
              data-name="Path 1378"
              d="M5.368 18.24a.971.971 0 01-.971-.971.971.971 0 01.971-.971h4.278a.971.971 0 01.971.971.971.971 0 01-.971.971H5.368z"
              fill="#404a4f"
            />
          </g>
          <g data-name="Group 1562">
            <path
              data-name="Path 1379"
              d="M19.308 18.24a.971.971 0 01-.971-.971.971.971 0 01.971-.971h.29a.971.971 0 01.971.971.971.971 0 01-.971.971h-.29z"
              fill="#404a4f"
            />
          </g>
          <g data-name="Group 1563">
            <path
              data-name="Path 1380"
              d="M17.717 18.24a.971.971 0 01-.971-.971.971.971 0 01.971-.971h.29a.971.971 0 01.971.971.971.971 0 01-.971.971h-.29z"
              fill="#404a4f"
            />
          </g>
          <g data-name="Group 1564">
            <path
              data-name="Path 1381"
              d="M14.413 18.24a.971.971 0 01-.971-.971.971.971 0 01.971-.971h.29a.971.971 0 01.971.971.971.971 0 01-.971.971h-.29z"
              fill="#404a4f"
            />
          </g>
        </g>
        <g data-name="Group 1567">
          <g data-name="Group 1566">
            <path
              data-name="Path 1382"
              d="M3.399 21.448a3.406 3.406 0 01-3.4-3.4V6.525a3.406 3.406 0 013.4-3.4h18.166a3.405 3.405 0 013.4 3.4v11.523a3.407 3.407 0 01-3.4 3.4H3.399zM1.943 6.527V18.05A1.471 1.471 0 003.4 19.506h18.166a1.471 1.471 0 001.457-1.456V6.527a1.471 1.471 0 00-1.457-1.456H3.399a1.471 1.471 0 00-1.456 1.457z"
              fill="#404a4f"
            />
          </g>
        </g>
        <g data-name="Group 1568">
          <path
            data-name="Path 1383"
            d="M4.564 5.07a.969.969 0 01-.791-.407.968.968 0 01-.128-.88L4.719.656A.973.973 0 015.637 0h13.682a.973.973 0 01.918.656l1.074 3.127-.918.316.918-.316a.968.968 0 01-.127.88.969.969 0 01-.791.407H4.564zm1.36-1.943h13.11l-.407-1.185h-12.3L5.92 3.127z"
            fill="#404a4f"
          />
        </g>
        <g data-name="Group 1569">
          <path
            data-name="Path 1384"
            d="M5.641 27.557a.97.97 0 01-.956-.8l-1.074-6.091a.965.965 0 01.212-.793.97.97 0 01.744-.347h15.831a.97.97 0 01.744.347.968.968 0 01.212.793l-1.074 6.091a.971.971 0 01-.956.8H5.641zm.815-1.942h12.053l.732-4.149H5.724l.732 4.149zm-1.889-4.149z"
            fill="#404a4f"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
