import React from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toggleSidebar } from "../../redux/preferenceSlice";
// Components
import Notification from "../Elements/Notification";
import Profile from "../Elements/Profile";
// Assets
import BurgerIcon from "../../assets/svg/burger";

const NavbarTop = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const sidebarOpen = useSelector((state) => state.preference.sidebarOpen);

  const getBreadcrumb = () => {
    let name = "";
    if (location.pathname === "/") {
      name = "Dashboard";
    } else {
      name = location.pathname.substring(1).charAt(0).toUpperCase() + location.pathname.slice(2);
    }
    return name;
  };

  return (
    <WrapperDiv sidebarOpen={sidebarOpen} className={`animate ${sidebarOpen ? "sidebarOpen" : "sidebarClosed"}`}>
      <InnerWrap className="flexSpaceCenter">
        <div className="flexNullCenter" style={{ width: "70%" }}>
          <BurgerWrapper className="flexNullCenter pointer" onClick={() => dispatch(toggleSidebar())}>
            <BurgerIconWrapper>
              <BurgerIcon />
            </BurgerIconWrapper>
          </BurgerWrapper>
          <BreadCrumb>
            <p className="font15">
              Energy Hero
              <span className="font15" style={{ margin: "0 5px" }}>
                /
              </span>
              <span className="font15">{getBreadcrumb()}</span>
            </p>
          </BreadCrumb>
        </div>
        <div className="flexNullCenter" style={{ maxWidth: '220px', paddingRight: '15px' }}>
          <Notification />
          <Profile />
        </div>
      </InnerWrap>
    </WrapperDiv>
  );
};

export default NavbarTop;

const WrapperDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 40;
  height: 70px;
  padding-right: 15px;
  background-color: #fafafa;
  border-bottom: 2px solid #eaeeee;
`;
const InnerWrap = styled.div`
  max-width: 1720px;
  height: 70px;
  margin: 0 auto;
`;
const BurgerWrapper = styled.button`
  background-color: transparent;
  height: 60px;
  width: 80px;
  max-width: 20%;
  border: 0px;
  outline: none;
  overflow-x: hidden;
`;
const BurgerIconWrapper = styled.div`
  width: 80px;
`;
const BreadCrumb = styled.div`
  max-width: 70%;
  p {
    min-height: 17px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;
