import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16.319} height={16.319} viewBox="0 0 16.319 16.319" {...props}>
      <g data-name="Group 2037">
        <g data-name="Group 15" transform="translate(7.317 4.097)" fill="#fead38">
          <path data-name="Rectangle 63" d="M.045 3.277h1.616v4.849H.045z" />
          <circle data-name="Ellipse 19" cx={0.842} cy={0.842} r={0.842} />
        </g>
        <g data-name="Group 16">
          <path
            data-name="Path 13"
            d="M0 8.159a8.159 8.159 0 018.16-8.16 8.159 8.159 0 018.159 8.16 8.158 8.158 0 01-8.159 8.159A8.159 8.159 0 010 8.159zm3.627-4.531A6.384 6.384 0 001.75 8.159a6.385 6.385 0 001.877 4.532 6.386 6.386 0 004.532 1.876 6.386 6.386 0 004.532-1.876 6.38 6.38 0 001.876-4.532 6.379 6.379 0 00-1.876-4.531 6.383 6.383 0 00-4.532-1.877 6.385 6.385 0 00-4.532 1.877z"
            fill="#fead38"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
