import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
// Components
import NavItem from "./NavItem";

const TabBar = ({ full }) => {
  const sidebarOpen = useSelector((state) => state.preference.sidebarOpen);
  return (
    <WrapperDiv>
      <div className="flexSpaceCenter flexColumn" style={{ height: "100%" }}>
        <UlWrap className="flexSpaceCenter flexColumn">
          <ListWraper full={full} open={sidebarOpen} className="animate">
            <NavItem full={full} icon="dashboard" path="/" title="Dashboard" />
          </ListWraper>
          <ListWraper full={full} open={sidebarOpen} className="animate">
            <NavItem full={full} icon="strom" path="/strom" title="Strom" />
          </ListWraper>
          <ListWraper full={full} open={sidebarOpen} className="animate">
            <NavItem full={full} icon="gas" path="/gas" title="Gas" />
          </ListWraper>
        </UlWrap>
        <UlWrap className="flexSpaceCenter flexColumn">
          <ListWraper full={full} open={sidebarOpen} className="animate">
            <NavItem full={full} icon="logout" title="Logout" withPopup />
          </ListWraper>
        </UlWrap>
      </div>
    </WrapperDiv>
  );
};

export default TabBar;

const WrapperDiv = styled.div`
  width: 100%;
  height: calc(100vh - 130px);
  display: block;
  z-index: 30;
  overflow-x: hidden;
`;
const UlWrap = styled.ul`
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;
const ListWraper = styled.li`
  width: ${(props) => (props.open || props.full ? "220px" : "80px")};
  overflow-x: hidden;
`;
