import * as React from "react";

function SvgComponent(props) {
  return (
    <svg data-name="Group 1444" xmlns="http://www.w3.org/2000/svg" width={26.178} height={26.733} viewBox="0 0 26.178 26.733" {...props}>
      <g data-name="Group 1326">
        <path
          data-name="Path 1284"
          d="M1.114 9.251a1.117 1.117 0 01-1.075-.822 1.115 1.115 0 01.512-1.252L12.526.154a1.116 1.116 0 011.127 0l11.975 7.023a1.115 1.115 0 01.511 1.252 1.116 1.116 0 01-1.075.822H1.114zm4.1-2.228h15.748l-7.874-4.618-7.874 4.618zm7.311-4.949l.563-.961-.563.961z"
          fill="#404a4f"
        />
      </g>
      <g data-name="Group 1327">
        <path
          data-name="Path 1285"
          d="M1.114 26.733A1.114 1.114 0 010 25.619a1.114 1.114 0 011.114-1.114h23.95a1.114 1.114 0 011.114 1.114 1.114 1.114 0 01-1.114 1.114H1.114z"
          fill="#404a4f"
        />
      </g>
      <g data-name="Group 1331">
        <g data-name="Group 1328">
          <path
            data-name="Path 1286"
            d="M3.948 21.039v-8.166a1.114 1.114 0 011.114-1.114 1.114 1.114 0 011.114 1.114v8.166a1.114 1.114 0 01-1.114 1.114 1.114 1.114 0 01-1.114-1.114z"
            fill="#404a4f"
          />
        </g>
        <g data-name="Group 1329">
          <path
            data-name="Path 1287"
            d="M11.975 21.039v-8.166a1.114 1.114 0 011.115-1.114 1.113 1.113 0 011.114 1.114v8.166a1.114 1.114 0 01-1.114 1.114 1.114 1.114 0 01-1.115-1.114z"
            fill="#404a4f"
          />
        </g>
        <g data-name="Group 1330">
          <path
            data-name="Path 1288"
            d="M20.002 21.039v-8.166a1.114 1.114 0 011.114-1.114 1.114 1.114 0 011.114 1.114v8.166a1.114 1.114 0 01-1.114 1.114 1.114 1.114 0 01-1.114-1.114z"
            fill="#404a4f"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
