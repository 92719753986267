import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sidebarOpen: true,
  mobileOpen: false,
  notificationOpen: false,
};

export const preferenceSlice = createSlice({
  name: "preference",
  initialState,
  reducers: {
    toggleSidebar: (state) => {
      state.sidebarOpen = !state.sidebarOpen;
    },
    openMobile: (state) => {
      state.mobileOpen = true;
    },
    closeMobile: (state) => {
      state.mobileOpen = false;
    },
    openNotification: (state) => {
      state.notificationOpen = true;
    },
    closeNotification: (state) => {
      state.notificationOpen = false;
    },
  },
});

export const { toggleSidebar, openMobile, closeMobile, openNotification, closeNotification } = preferenceSlice.actions;

export default preferenceSlice.reducer;
