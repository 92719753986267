import React from "react";
import styled from "styled-components";
// Assets
import CheckmarkIcon from "../../assets/svg/checkmark";

const Spinner = ({ text, noSpinner, showCheckmark, transparent }) => {
  return (
    <Wrapper className="flexCenter" transparent={transparent}>
      <div className="flexColumn flexNullCenter">
        {noSpinner ? null : <div className="loader"></div>}
        {showCheckmark ? (
          <CheckmarkDiv className="flexCenter">
            <CheckmarkIcon width={20} height={18} color="#58C3A9" />
          </CheckmarkDiv>
        ) : null}
        <p className="textCenter font15" style={{ paddingTop: "40px", color: "#6D6D77" }}>
          {text}
        </p>
      </div>
    </Wrapper>
  );
};

export default Spinner;

const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: ${(props) => (props.transparent ? "rgba(255, 255, 255, 0.8)" : "rgba(255, 255, 255, 1)")};
  z-index: 9999;
`;
const CheckmarkDiv = styled.div`
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: #e0f3ef;
`;
