import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={28.577} height={30.868} viewBox="0 0 28.577 30.868" {...props}>
      <g data-name="Group 1603">
        <g data-name="Group 1598">
          <path
            data-name="Path 1403"
            d="M20.342 16.473a1.141 1.141 0 01-1.141-1.141 1.141 1.141 0 011.141-1.141h1.045a1.141 1.141 0 011.141 1.141 1.142 1.142 0 01-1.141 1.141h-1.045z"
            fill="#404a4f"
          />
        </g>
        <g data-name="Group 1599">
          <path
            data-name="Path 1404"
            d="M2.892 17.046A5.259 5.259 0 01.859 15.56a3.7 3.7 0 01-.861-2.335 3.67 3.67 0 01.777-2.229 5.1 5.1 0 011.858-1.474 1.141 1.141 0 011.524.531 1.142 1.142 0 01-.531 1.524 2.833 2.833 0 00-1.033.8 1.377 1.377 0 00-.312.848 1.4 1.4 0 00.351.9 3.006 3.006 0 001.157.825 1.142 1.142 0 01.6 1.5 1.143 1.143 0 01-1.05.693 1.138 1.138 0 01-.448-.092z"
            fill="#404a4f"
          />
        </g>
        <g data-name="Group 1600">
          <path
            data-name="Path 1405"
            d="M17.808 30.868a1.142 1.142 0 01-1.045-.68l-.355-.806a14.218 14.218 0 01-1.775.111 14.165 14.165 0 01-1.974-.138l-.367.833a1.144 1.144 0 01-1.044.68h-3.1a1.147 1.147 0 01-.807-.334 1.147 1.147 0 01-.335-.807v-2.544a10.415 10.415 0 01-4.726-8.531 10.2 10.2 0 013.679-7.721 13.254 13.254 0 018.677-3.119 13.989 13.989 0 013.489.438 6.126 6.126 0 014.5-2.061 4.865 4.865 0 011.608.248 1.138 1.138 0 01.718 1.324l-.887 3.884a10.47 10.47 0 012.454 4.032h.918a1.149 1.149 0 01.807.334 1.15 1.15 0 01.334.807v4.762a1.132 1.132 0 01-.335.807 1.131 1.131 0 01-.806.334H26.09a11.166 11.166 0 01-4.039 4.6v2.4a1.134 1.134 0 01-.335.807 1.131 1.131 0 01-.806.334h-3.1zm.332-3.212l.41.93h1.219v-1.9a1.144 1.144 0 01.565-.985 8.852 8.852 0 003.923-4.518 1.141 1.141 0 011.072-.749h.965v-2.479h-.646a1.143 1.143 0 01-1.114-.894 8.145 8.145 0 00-2.5-4.212 1.145 1.145 0 01-.34-1.094l.749-3.281a4.024 4.024 0 00-3.033 1.773 1.137 1.137 0 01-1.246.385 11.635 11.635 0 00-3.535-.542 10.974 10.974 0 00-7.184 2.562 7.927 7.927 0 00-2.891 6 8.233 8.233 0 004.183 6.94 1.142 1.142 0 01.544.972v2.021H10.5l.428-.97a1.143 1.143 0 011.278-.656 11.788 11.788 0 002.423.25 11.8 11.8 0 002.246-.214 1.146 1.146 0 01.22-.021 1.138 1.138 0 011.042.681z"
            fill="#404a4f"
          />
        </g>
        <g data-name="Group 1601">
          <path
            data-name="Path 1406"
            d="M14.784 24.858a13.934 13.934 0 01-3.878-.543 1.142 1.142 0 01-.78-1.414 1.141 1.141 0 011.414-.78 11.645 11.645 0 003.244.454 11.775 11.775 0 002.587-.285 1.141 1.141 0 011.364.862 1.142 1.142 0 01-.862 1.365 14.042 14.042 0 01-3.08.341h-.009z"
            fill="#404a4f"
          />
        </g>
        <g data-name="Group 1602">
          <path
            data-name="Path 1407"
            d="M6.918 7.601a5.319 5.319 0 01-.493-2.245v-.144A5.354 5.354 0 0111.775 0h.115a5.354 5.354 0 015.241 5.351v.091a1.141 1.141 0 01-1.141 1.141 1.141 1.141 0 01-1.141-1.141v-.091a3.069 3.069 0 00-3.006-3.068h-.066A3.072 3.072 0 008.708 5.29v.067a3.04 3.04 0 00.282 1.285 1.142 1.142 0 01-.556 1.516 1.137 1.137 0 01-.479.106 1.141 1.141 0 01-1.036-.661z"
            fill="#404a4f"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
