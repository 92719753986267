import React from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";
// Assets
import StromDetail from "../../assets/svg/stromDetail";
import GasDetail from "../../assets/svg/gasDetail";


const ProductSelect = ({ title, active, to }) => {
  return (
    <Wrapper
      className="flexCenter pointer animate"
      to={to ? to : null}
      style={active ? { border: "2px solid #f58600" } : { border: "2px solid #fafafa" }}
    >
      {title && title === "Strom" ? <StromDetail /> : <GasDetail />}
      <p className="font15 bold" style={{ padding: "0 20px" }}>
        {title}
      </p>
    </Wrapper>
  );
};

export default ProductSelect;

const Wrapper = styled(Link)`
  background-color: #fafafa;
  height: 65px;
  width: 100%;
  outline: none;
  border: 0px;
  margin: 0 10px;
  border-radius: 0.5rem;
  padding: 0 20px;
  color: #171727;
  :hover {
    background-color: #f58600;
  }
`;

