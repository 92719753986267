import * as React from "react";

function SvgComponent(props) {
  return (
    <svg data-name="Group 1529" xmlns="http://www.w3.org/2000/svg" width={21.359} height={21.359} viewBox="0 0 21.359 21.359" {...props}>
      <g data-name="Group 1524">
        <g data-name="Group 1523">
          <path
            data-name="Path 1353"
            d="M10.428 18.541a8.091 8.091 0 01-5.737-2.376l-3.218-3.219a.945.945 0 01-.276-.665.944.944 0 01.276-.665L11.616 1.473a.94.94 0 011.33 0l3.2 3.2.011.011.013.013a8.09 8.09 0 012.368 5.729 8.09 8.09 0 01-2.376 5.737 8.088 8.088 0 01-5.732 2.376zm-4.406-3.707a6.207 6.207 0 004.406 1.825 6.206 6.206 0 004.406-1.825 6.209 6.209 0 001.825-4.406 6.207 6.207 0 00-1.825-4.406l-.006-.006-.008-.008-.011-.011-2.526-2.526-8.813 8.813 2.553 2.553zm5.594-12.031l.666-.666-.666.666z"
            fill={props.color}
          />
        </g>
      </g>
      <g data-name="Group 1525">
        <path
          data-name="Path 1354"
          d="M19.752 21.083l-4.367-4.367a.941.941 0 010-1.331.941.941 0 011.331 0l4.368 4.367a.941.941 0 010 1.331.938.938 0 01-.665.275.939.939 0 01-.666-.275z"
          fill={props.color}
        />
      </g>
      <g data-name="Group 1528">
        <g data-name="Group 1526">
          <path
            data-name="Path 1355"
            d="M4.015 10.321L.277 6.583a.941.941 0 010-1.331.941.941 0 011.331 0l3.738 3.739a.94.94 0 010 1.33.937.937 0 01-.666.276.936.936 0 01-.665-.276z"
            fill={props.color}
          />
        </g>
        <g data-name="Group 1527">
          <path
            data-name="Path 1356"
            d="M8.991 5.344L5.253 1.606a.941.941 0 010-1.331.941.941 0 011.331 0l3.738 3.738a.941.941 0 010 1.33.938.938 0 01-.665.276.938.938 0 01-.665-.276z"
            fill={props.color}
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
