import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 457.86 206.497" {...props}>
      <path
        data-name="Path 1647"
        d="M148.321 162.178h-24.149v41.574h-41.3v-41.574H0v-29.5L85.205 3.152h38.967v126.1h24.149zm-65.448-32.926V95.225q0-1.784.069-5.145t.206-7.615q.137-4.253.343-8.781t.412-8.644q.206-4.116.412-7.2t.343-4.459h-1.236q-2.47 5.763-5.557 12.211a138.323 138.323 0 01-7.066 12.9l-33.479 50.76zm217.478-25.8a224.724 224.724 0 01-3.773 43.357q-3.773 19.072-12.074 32.381a56.181 56.181 0 01-21.4 20.307q-13.1 7-31.626 7-17.425 0-30.323-7a58.194 58.194 0 01-21.4-20.307q-8.521-13.307-12.704-32.379t-4.186-43.359a226.886 226.886 0 013.773-43.495q3.773-19.205 12.005-32.516A56.359 56.359 0 01199.91 7.066Q212.945 0 231.468 0q17.288 0 30.186 7a59.261 59.261 0 0121.542 20.307q8.644 13.309 12.9 32.518t4.255 43.627zm-95.222 0q0 34.3 5.9 51.59t20.439 17.29q14.27 0 20.512-17.151t6.243-51.727q0-34.439-6.243-51.8t-20.512-17.357a17.969 17.969 0 00-12.28 4.391q-5.008 4.391-8.164 13.035a92.232 92.232 0 00-4.528 21.61 289.95 289.95 0 00-1.372 30.119zm252.731 58.726h-24.149v41.574h-41.3v-41.574h-82.872v-29.5L394.744 3.152h38.967v126.1h24.148zm-65.448-32.926V95.225q0-1.784.069-5.145t.206-7.615q.137-4.253.343-8.781t.412-8.644q.206-4.116.412-7.2t.343-4.459h-1.236q-2.47 5.763-5.557 12.211a138.321 138.321 0 01-7.066 12.9l-33.479 50.76z"
        fill="#e5e5e7"
      />
    </svg>
  );
}

export default SvgComponent;
