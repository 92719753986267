import * as React from "react";

function SvgComponent(props) {
  return (
    <svg data-name="Group 1533" xmlns="http://www.w3.org/2000/svg" width={23.11} height={22.492} viewBox="0 0 23.11 22.492" {...props}>
      <g data-name="Group 1515">
        <g data-name="Group 1514">
          <path
            data-name="Path 1349"
            d="M2.705 10.497a2.71 2.71 0 01-2.7-2.705V2.706a2.71 2.71 0 012.7-2.705h5.086a2.71 2.71 0 012.706 2.705v5.086a2.71 2.71 0 01-2.706 2.705H2.705zm-.58-8.372a.817.817 0 00-.243.581v5.086a.816.816 0 00.243.58.816.816 0 00.58.243h5.086a.818.818 0 00.581-.243.816.816 0 00.242-.58V2.706a.817.817 0 00-.242-.581.818.818 0 00-.581-.243H2.705a.817.817 0 00-.58.243z"
            fill={props.color}
          />
        </g>
      </g>
      <g data-name="Group 1517">
        <g data-name="Group 1516">
          <path
            data-name="Path 1350"
            d="M15.319 10.497a2.71 2.71 0 01-2.705-2.705V2.706A2.71 2.71 0 0115.319.001h5.086a2.709 2.709 0 012.7 2.705v5.086a2.709 2.709 0 01-2.7 2.705h-5.086zm-.581-8.372a.819.819 0 00-.243.581v5.086a.818.818 0 00.243.58.817.817 0 00.581.243h5.086a.817.817 0 00.581-.243.814.814 0 00.243-.58V2.706a.815.815 0 00-.243-.581.817.817 0 00-.581-.243h-5.086a.817.817 0 00-.581.243z"
            fill={props.color}
          />
        </g>
      </g>
      <g data-name="Group 1519">
        <g data-name="Group 1518">
          <path
            data-name="Path 1351"
            d="M15.324 22.492a2.71 2.71 0 01-2.706-2.7v-5.086a2.71 2.71 0 012.706-2.7h5.086a2.709 2.709 0 012.705 2.7v5.086a2.709 2.709 0 01-2.705 2.7h-5.086zm-.581-8.372a.817.817 0 00-.243.581v5.086a.817.817 0 00.243.581.818.818 0 00.581.243h5.086a.819.819 0 00.581-.243.817.817 0 00.243-.581v-5.086a.817.817 0 00-.243-.581.819.819 0 00-.581-.243h-5.086a.817.817 0 00-.581.243z"
            fill={props.color}
          />
        </g>
      </g>
      <g data-name="Group 1521">
        <g data-name="Group 1520">
          <path
            data-name="Path 1352"
            d="M2.705 22.492A2.709 2.709 0 010 19.792v-5.086a2.709 2.709 0 012.705-2.7h5.087a2.709 2.709 0 012.7 2.7v5.086a2.709 2.709 0 01-2.7 2.7H2.705zm-.581-8.372a.817.817 0 00-.243.581v5.086a.817.817 0 00.243.581.817.817 0 00.581.243h5.087a.817.817 0 00.581-.243.818.818 0 00.243-.581v-5.086a.818.818 0 00-.243-.581.817.817 0 00-.581-.243H2.705a.817.817 0 00-.581.243z"
            fill={props.color}
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
