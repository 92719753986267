import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={34} height={13} viewBox="0 0 34 13" {...props}>
      <g data-name="Polygon 4" fill="#fff">
        <path d="M2.954 12L17 1.259 31.046 12H2.954z" />
        <path d="M17 2.518L5.908 11h22.184L17 2.518m0-2.26c.214 0 .428.07.607.207l14.047 10.74c.76.582.348 1.795-.608 1.795H2.954c-.956 0-1.367-1.213-.608-1.794L16.393.465A.998.998 0 0117 .259z" />
      </g>
    </svg>
  );
}

export default SvgComponent;
