import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    box-sizing: border-box;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  a {
    text-decoration: none!important;
  }
  input:-webkit-autofill {
      -webkit-box-shadow:0 0 0 50px #fff inset; /* Change the color to your own background color */
      box-shadow: transparent;
      -webkit-text-fill-color: #fff;
  }
  body {
    background: #fff;
    color: #171727;
    font-family: 'Noto Sans', sans-serif;
    overflow-x: hidden;
    font-size: 16px;
    line-height: 1.4rem;
    font-weight: 400;
  }
  .container {
    width: 100%;
    max-width: 1720px;
    margin: 0 auto;
    padding: 30px;
  }
  .safeArea {
    padding: 80px 0 0 0;
  }
  .sidebarOpen {
    margin-left: 220px;
    width: calc(100% - 220px);
  }
  .sidebarClosed {
    margin-left: 80px;
    width: calc(100% - 80px);
  }
  @media only screen and (max-width: 859px) {
    .safeArea {
      padding: 60px 0;
    }
    .sidebarOpen {
      margin-left: 0px;
      width: 100%;
    }
    .sidebarClosed {
      margin-left: 0px;
      width: 100%;
    }
    .container {
      padding: 15px 15px 30px 15px;
    }
  }
  .regular {
    font-weight: 400;
  }
  .bold {
    font-weight: 700;
  }

  // HELLPERS
  .textCenter {
    text-align: center
  }
  .animate {
    transition: 0.3s ease;
  }
  .pointer {
    cursor: pointer;
  }
  .relative {
    position: relative;
  }
  .textCenter {
    text-align: center;
  }
  .textLeft {
    text-align: left;
  }
  .textRight {
    text-align: right;
  }
  .x100 {
    width: 100%;
  }
  // FLEXBOX
  .flex {
    display: flex;
  }
  .flexSpaceCenter {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .flexNullCenter {
    display: flex;
    align-items: center;
  }
  .flexColumn {
    flex-direction: column;
  }
  .flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .flexSpaceNull {
    display: flex;
    justify-content: space-between;
  }

  // FONT SIZES
  .font11 {
    font-size: 0.688rem;
  }
  .font12 {
    font-size: 0.75rem;
  }
  .font13 {
    font-size: 0.813rem;
  }
  .font14 {
    font-size: 0.875rem;
  }
  .font15 {
    font-size: 0.938rem;
  }
  .font18 {
    font-size: 1.125rem;
  }
  .font20 {
    font-size: 1.25rem;
  }
  .font25 {
    font-size: 1.563rem;
  }
  .font30 {
    font-size: 1.875rem;
  }
  .font40 {
    font-size: 2.5rem;
  }
  @media only screen and (max-width: 859px) {
    .font11 {
      font-size: 0.688rem;
    }
    .font12 {
      font-size: 0.688rem;
    }
    .font13 {
      font-size: 0.75rem;
    }
    .font14 {
      font-size: 0.813rem;
    }
    .font15 {
      font-size: 0.813rem;
    }
    .font18 {
      font-size: 0.938rem;
    }
    .font20 {
      font-size: 1.125rem;
    }
    .font25 {
      font-size: 1.25rem;
    }
    .font30 {
      font-size: 1.5rem;
    }
    .font40 {
      font-size: 1.875rem;
    }
  }
  // BORDER RADIUS
  .radius8 {
    border-radius: 1rem;
  }
  .radius6 {
    border-radius: 0.6rem;
  }
  // COLORS
  .orangeColor {
    color: #F9A81A;
  }
  .orangeColorBg {
    background-color: #F9A81A;
  }
  .blackColor {
    color: #171727;
  }
  .blackColorBg {
    background-color: #171727;
  }
  .softBlackColor {
    color: #404A4F;
  }
  .softBlackColorBg {
    background-color: #404A4F;
  }
  .lightColor {
    color: #FAFAFA;
  }
  .lightColorBg {
    background-color: #FAFAFA;
  }
  .whiteColor {
    color: #fff;
  }
  .whiteColorBg {
    background-color: #fff;
  }
  .greyColor {
    color: #B5B5BA;
  }
  .greyColorBg {
    background-color: #B5B5BA;
  }
  .redColor {
    color: #FF1500;
  }



  

  // ANIMATION
  .moveLabelUp {
    animation-name: moveLabelUpAnimation;
    animation-duration: 0.3s;
  }
  @keyframes moveLabelUpAnimation {
    from {top: 10px;}
    to {top: -5px;}
  }

  // SPACING
  .margin20-0 {
    margin: 20px 0;
  }

  // Spinner
  .spinner {
    width:20px;
    height:15px;
    --c:linear-gradient(currentColor 0 0);
    background: 
      var(--c) 0%   50%,
      var(--c) 50%  50%,
      var(--c) 100% 50%;
    background-size:4px 100%;
    background-repeat: no-repeat;
    animation:spinner 1s infinite linear;
  }
  @keyframes spinner {
      33%{background-size:4px 10% ,4px 100%,4px 100%}
      50%{background-size:4px 100%,4px 10% ,4px 100%}
      66%{background-size:4px 100%,4px 100%,4px 10% }
  }
  .loader {
    border: 8px solid #EAEEEE; /* Light grey */
    border-top: 8px solid #F9A81A; /* orange */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1.5s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;
