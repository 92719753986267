import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={26.167} height={31.777} viewBox="0 0 26.167 31.777" {...props}>
      <g data-name="Group 1597">
        <g data-name="Group 1592">
          <g data-name="Group 1591">
            <path
              data-name="Path 1399"
              d="M2.907 27.996a2.911 2.911 0 01-2.906-2.907V13.904a4.257 4.257 0 01.593-2.069 4.277 4.277 0 011.478-1.565l4.667-2.762a4.138 4.138 0 012.094-.529 4.137 4.137 0 012.093.529l4.669 2.762a4.281 4.281 0 011.477 1.565 4.268 4.268 0 01.594 2.069v11.185a2.912 2.912 0 01-2.906 2.907H2.907zM7.924 9.509l-4.667 2.763a2.009 2.009 0 00-.642.715 2.01 2.01 0 00-.289.917v11.185a.594.594 0 00.581.581h11.852a.594.594 0 00.581-.581V13.904a2.009 2.009 0 00-.288-.917 2.011 2.011 0 00-.642-.715L9.741 9.509a1.893 1.893 0 00-.909-.2h-.018a1.883 1.883 0 00-.891.2z"
              fill="#404a4f"
            />
          </g>
        </g>
        <g data-name="Group 1593">
          <path
            data-name="Path 1400"
            d="M7.669 10.641V4.83a1.162 1.162 0 011.163-1.163A1.163 1.163 0 019.995 4.83v5.811a1.163 1.163 0 01-1.163 1.163 1.163 1.163 0 01-1.163-1.163z"
            fill="#404a4f"
          />
        </g>
        <g data-name="Group 1595">
          <g data-name="Group 1594">
            <path
              data-name="Path 1401"
              d="M6.096 31.199a2.448 2.448 0 01-1-1.686l-.163-1.418a1.163 1.163 0 011.021-1.288 1.162 1.162 0 011.289 1.021l.164 1.42s-.008.016.074.081a.73.73 0 00.424.122.906.906 0 00.11-.006l15.314-1.77a.591.591 0 00.515-.578.536.536 0 000-.067l-1.787-15.466a2.113 2.113 0 00-.4-.9 2.1 2.1 0 00-.724-.662l-6.916-3.279a1.523 1.523 0 00-.629-.118 1.877 1.877 0 00-1.087.317L9.924 8.816a1.163 1.163 0 01-1.634-.184 1.163 1.163 0 01.185-1.634l2.377-1.893a4.118 4.118 0 012.536-.825 3.808 3.808 0 011.626.344l6.915 3.279a4.354 4.354 0 011.619 1.41 4.348 4.348 0 01.814 1.986l1.788 15.468a3.079 3.079 0 01.019.332 2.913 2.913 0 01-2.573 2.887L8.28 31.756a3.431 3.431 0 01-.375.022 3.042 3.042 0 01-1.806-.578z"
              fill="#404a4f"
            />
          </g>
        </g>
        <g data-name="Group 1596">
          <path
            data-name="Path 1402"
            d="M11.809 5.308l-.463-4.012A1.161 1.161 0 0112.367.008a1.162 1.162 0 011.289 1.021l.464 4.012a1.164 1.164 0 01-1.022 1.289 1.246 1.246 0 01-.134.008 1.164 1.164 0 01-1.154-1.029z"
            fill="#404a4f"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
