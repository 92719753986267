import React from "react";
import styled from "styled-components";
// Components
import Backdrop from "../Elements/Backdrop";
import FullButton from "../Buttons/FullButton";
// Assets
import ErrorIcon from "../../assets/svg/Error";

const ModalSuccess = ({ action, error }) => {
  return (
    <Wrapper className="flexCenter">
      <Backdrop />
      <PopupDiv className="flexSpaceCenter flexColumn relative">
        {error ? (
          <>
            <div style={{ marginBottom: "20px" }}>
              <ErrorIcon width="50px" height="50px" />
            </div>
            <p className="textCenter font25 bold" style={{ marginBottom: "15px" }}>
              Error!
            </p>
          </>
        ) : null}
        {error ? (
          <p className="textCenter">Ihre IBAN-Nummer ist ungültig.</p>
        ) : (
          <p className="textCenter">Deine Änderungen wurden an uns übermittelt und werden geprüft.</p>
        )}
        <div className="flexNullCenter" style={{ marginTop: "40px" }}>
          <div style={{ width: "120px", marginLeft: "5px" }}>
            <FullButton title="Ok" action={action ? () => action() : null} />
          </div>
        </div>
      </PopupDiv>
    </Wrapper>
  );
};

export default ModalSuccess;

const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 99999;
  top: 0px;
  left: 0px;
`;
const PopupDiv = styled.div`
  background-color: #fff;
  z-index: 9999;
  padding: 50px 80px 30px 80px;
  border-radius: 10px;
  min-width: 320px;
  @media (max-width: 460px) {
    margin: 0 10px;
  }
`;
