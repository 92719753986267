import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getData, postData } from "../helpers/Api";
import authService from "../components/api-authorization/AuthorizeService";

// Get Token to Redux State
export const getToken = createAsyncThunk("token", async (thunkAPI) => {
  try {
    let token = await authService.getAccessToken();
    if (token) {
      return token;
    } else {
      return thunkAPI.rejectWithValue();
    }
  } catch (e) {
    thunkAPI.rejectWithValue(e.response.data);
  }
});
// Get Accounts for User
export const getAccounts = createAsyncThunk("salesforceaccount", async (token, thunkAPI) => {
  try {
    getData.headers = { Authorization: `Bearer ${token}` };
    const response = await fetch(`salesforceaccount`, getData);
    if (response.status === 401) {
      authService.signOut();
    }
    let data = await response.json();
    if (response.status === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue(data);
    }
  } catch (e) {
    thunkAPI.rejectWithValue(e.response.data);
  }
});
// Get Accounts Detail for User
export const getAccountDetail = createAsyncThunk("account-detail", async ({token, id}, thunkAPI) => {
  try {
    getData.headers = { Authorization: `Bearer ${token}` };
    const response = await fetch(`salesforceaccount/getaccountdetails/${id}`, getData);
    let data = await response.json();
    if (response.status === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue(data);
    }
  } catch (e) {
    thunkAPI.rejectWithValue(e.response.data);
  }
});
// Get Address Detail for Account/Address
export const getAddressDetail = createAsyncThunk("address-detail", async ({ token, accId, addId }, thunkAPI) => {
  try {
    getData.headers = { Authorization: `Bearer ${token}` };
    const response = await fetch(`salesforceaccount/getaddressdetails/${accId}/${addId}`, getData);
    let data = await response.json();
    if (response.status === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue(data);
    }
  } catch (e) {
    thunkAPI.rejectWithValue(e.response.data);
  }
});
// Save Electric / Gas Changes
export const postSaveChanges = createAsyncThunk("SaveChanges", async (data, thunkAPI) => {
  postData.headers.Authorization = `Bearer ${thunkAPI.getState().user.token}`;
  postData.body = JSON.stringify(data);
  try {
    const response = await fetch(`Changes/SaveChanges`, postData);
    let data = await response.json();
    if (response.status === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue(data);
    }
  } catch (e) {
    thunkAPI.rejectWithValue(e.response.data);
  }
});

export const userSlice = createSlice({
  name: "user",
  initialState: {
    token: null,
    accounts: null,
    selectedAccount: null,
    selectedAddress: null,
    accDetail: null,
    addrDetail: null,
    popup: false,
    isFetching: false,
    isSuccess: false,
    isError: false,
    savedChanges: false,
    errorMessage: "",
  },
  reducers: {
    clearState: (state) => {
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = false;
      return state;
    },
    togglePopup: (state, action) => {
      state.popup = action.payload;
    },
    selectAccount: (state, action) => {
      state.selectedAccount = action.payload;
    },
    selectAddress: (state, action) => {
      state.selectedAddress = action.payload;
    },
    closeSuccessModal: (state) => {
      state.savedChanges = false;
      state.addrDetail.dashboardState.invalidIban = false;
    },
  },
  extraReducers: {
    [getToken.fulfilled]: (state, { payload }) => {
      state.token = payload;
      state.isFetching = false;
      state.isSuccess = true;
    },
    [getToken.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = "there is a problem getting token";
    },
    [getToken.pending]: (state) => {
      state.isFetching = true;
    },
    [getAccounts.fulfilled]: (state, { payload }) => {
      state.accounts = payload;
      state.isFetching = false;
      state.isSuccess = true;
    },
    [getAccounts.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload ? payload.error : "there is a problem getting data from server";
    },
    [getAccounts.pending]: (state) => {
      state.isFetching = true;
    },
    [getAccountDetail.fulfilled]: (state, { payload }) => {
      state.accDetail = payload;
      state.isFetching = false;
      state.isSuccess = true;
    },
    [getAccountDetail.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload ? payload.error : "there is a problem getting data from server";
    },
    [getAccountDetail.pending]: (state) => {
      state.isFetching = true;
    },
    [getAddressDetail.fulfilled]: (state, { payload }) => {
      state.addrDetail = payload;
      state.isFetching = false;
      state.isSuccess = true;
    },
    [getAddressDetail.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload ? payload.error : "there is a problem getting data from server";
    },
    [getAddressDetail.pending]: (state) => {
      state.isFetching = true;
    },
    [postSaveChanges.fulfilled]: (state, { payload }) => {
      state.addrDetail.dashboardState = payload;
      state.isFetching = false;
      state.isSuccess = true;
      state.savedChanges = true;
    },
    [postSaveChanges.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload ? payload.error : "there is a problem sending data to server";
    },
    [postSaveChanges.pending]: (state) => {
      state.isFetching = true;
    },
  },
});

export const { clearState, togglePopup, selectAccount, selectAddress, closeSuccessModal } = userSlice.actions;

export const userSelector = (state) => state.user;
