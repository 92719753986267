import * as React from "react";

function SvgComponent(props) {
  return (
    <svg data-name="Group 1446" xmlns="http://www.w3.org/2000/svg" width={25.151} height={21.309} viewBox="0 0 25.151 21.309" {...props}>
      <g data-name="Group 1309">
        <g data-name="Group 1307">
          <path
            data-name="Path 1272"
            d="M3.596 5.569A5.568 5.568 0 019.165 0a5.569 5.569 0 015.569 5.569 5.569 5.569 0 01-5.569 5.568 5.568 5.568 0 01-5.569-5.568zm2 0a3.574 3.574 0 003.569 3.569 3.574 3.574 0 003.569-3.569 3.575 3.575 0 00-3.569-3.57 3.575 3.575 0 00-3.569 3.57z"
            fill="#404a4f"
          />
        </g>
        <g data-name="Group 1308">
          <path
            data-name="Path 1273"
            d="M.03 20.552a.843.843 0 01-.029-.25c0-.189.02-2.151.02-2.172a5.569 5.569 0 015.569-5.569h7.668a5.569 5.569 0 015.569 5.569c0 .02.02 2 .02 2.175v.01a1 1 0 01-1 1 1 1 0 01-1-1v-.01c0-.146-.02-2.122-.02-2.175a3.574 3.574 0 00-3.569-3.57H5.59a3.574 3.574 0 00-3.57 3.57L2 20.281a1 1 0 01-.758 1 .991.991 0 01-.243.03 1 1 0 01-.969-.757z"
            fill="#404a4f"
          />
        </g>
      </g>
      <g data-name="Group 1310">
        <path
          data-name="Path 1274"
          d="M16.112 9.247a1 1 0 01-.188-1.4 1 1 0 011.4-.188 1.994 1.994 0 001.219.412 2.012 2.012 0 002.009-2.009 2.012 2.012 0 00-2.009-2.01 1.989 1.989 0 00-.782.158 1 1 0 01-1.311-.531 1 1 0 01.531-1.31 3.994 3.994 0 011.561-.315 4.009 4.009 0 014.009 4.009 4.008 4.008 0 01-4.009 4.008 4 4 0 01-2.432-.821z"
          fill="#404a4f"
        />
      </g>
      <g data-name="Group 1311">
        <path
          data-name="Path 1275"
          d="M23.154 17.021v-.006l-.013-1.433a2.012 2.012 0 00-2.009-2.009h-2.715a1 1 0 01-1-1 1 1 0 011-1h2.715a4.008 4.008 0 014.008 4.009c0 .008.013 1.317.013 1.433v.006a1 1 0 01-1 1 1 1 0 01-1-1z"
          fill="#404a4f"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
