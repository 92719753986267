import React from "react";
import styled from "styled-components";
// Components
import FullButton from "../components/Buttons/FullButton";
// Assets
import LogoBigDark from "../assets/svg/logoBigDark";
import Sign404 from "../assets/svg/404";

const Error404 = () => {

  return (
    <PageWrapper>
      <InnerWrapper className="flexCenter">
        <div className="flexColumn" style={{ height: "50%" }}>
          <LogoBigDark/>
          <div style={{ padding: "60px 0 30px 0" }}>
            <Sign404 width={"30vw"} height={"100%"} />
          </div>
          <p className="font20 bold">whoops... this page is not available</p>
          <div style={{ maxWidth: "160px", margin: "30px auto 0 auto" }}>
            <FullButton title="Back to home" link='/' />
          </div>
        </div>
      </InnerWrapper>
    </PageWrapper>
  );
};

export default Error404;

const PageWrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
`;
const InnerWrapper = styled.div`
  width: 100%;
  max-width: 720px;
  height: 100%;
  margin: 0 auto;
  text-align: center;
`;