import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={22.204} height={22.139} viewBox="0 0 22.204 22.139" {...props}>
      <path
        data-name="Union 4"
        d="M2.738 22.139A2.745 2.745 0 010 19.4V2.741A2.744 2.744 0 012.738 0h2.947a.953.953 0 010 1.907H2.738a.839.839 0 00-.833.834V19.4a.839.839 0 00.833.834h2.947a.954.954 0 010 1.907zm8.494-5.831L6.67 11.74l-.012-.011a.955.955 0 010-1.349l4.574-4.58a.953.953 0 111.348 1.35L9.631 10.1h6.846a.954.954 0 010 1.907H9.631l2.948 2.951a.953.953 0 11-1.347 1.349zm8.74-4.3a.954.954 0 010-1.907h1.28a.954.954 0 010 1.907z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgComponent;
