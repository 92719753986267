import React from "react";
import styled from "styled-components";
// Assets
import ElectricIcon from "../../assets/svg/nav/electric";
import GasIcon from "../../assets/svg/nav/gas";

const NotificationBox = ({energy, title, date, changeType}) => {
  return (
    <Wrapper className="flexNullCenter radius8">
      <Icon className="flexCenter">{energy ? energy === "Electricity" ? <ElectricIcon color="#fff" /> : <GasIcon color="#fff" /> : null}</Icon>
      <Info>
        <div className="flexSpaceCenter" style={{ width: "100%" }}>
          <p className="font13" style={{ color: "#8d8d95" }}>
            {changeType ? changeType : null}
          </p>
          <p className="font13" style={{ color: "#8d8d95" }}>
            {date ? date : null}
          </p>
        </div>
        <p className="font13" style={{ width: "100%" }}>
          {title ? title : null}
        </p>
      </Info>
    </Wrapper>
  );
};

export default NotificationBox;

const Wrapper = styled.div`
  width: 100%;
  background-color: #fafafa;
  margin: 10px 0;
  padding: 10px;
`;
const Icon = styled.div`
  background-color: #f9a81a;
  width: 38px;
  height: 38px;
  min-width: 38px;
  max-height: 38px;
  max-width: 38px;
  min-height: 38px;
  border-radius: 50%;
`;
const Info = styled.div`
  width: 100%;
  margin-left: 10px;
`;