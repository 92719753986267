import React from "react";
import styled from "styled-components";

const CopyRight = () => {

  const getYear = () => {
      let d = new Date();
      return d.getFullYear();
  }

  return (
    <Wrapper>
      <InnerWrap>
        <p className="font11"> © {getYear()} All Rights Reserved</p>
      </InnerWrap>
    </Wrapper>
  );
};

export default CopyRight;

const Wrapper = styled.div`
  height: 30px;
  width: 100%;
  position: fixed;
  bottom: 0px;
  left: 0px;
  z-index: 9!important;
  background-color: #fff;
  
`;
const InnerWrap = styled.div`
  max-width: 1720px;
  height: 70px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  padding: 10px 15px 0 0;
`;