export const maskStr = (str) => {
  let word = str,
    masked = word.replace(/\w/g, "*");
  if (word.length > 8) {
    masked = word.substring(0, 5) + masked.substring(5, word.length - 4) + word.substring(word.length - 4);
  } else {
    return masked;
  }
  return masked;
};
