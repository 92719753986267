import * as React from "react";

function SvgComponent(props) {
  return (
    <svg data-name="Group 1441" xmlns="http://www.w3.org/2000/svg" width={23.468} height={23.458} viewBox="0 0 23.468 23.458" {...props}>
      <g data-name="Group 1333">
        <path
          data-name="Path 1289"
          d="M16.92 23.458c-3.328-.031-7.389-2.147-11-5.672l-.083-.081-.028-.03-.05-.049-.048-.049C2.166 13.953.038 9.877.011 6.54a4.956 4.956 0 011.329-3.6l.032-.031 1.9-1.9a1.972 1.972 0 011.4-.58 1.973 1.973 0 011.4.578l3.943 3.943a1.976 1.976 0 01.578 1.4 1.976 1.976 0 01-.578 1.4L8.084 9.681a23.225 23.225 0 002.6 3.1 23.334 23.334 0 003.1 2.606l1.93-1.929a1.973 1.973 0 011.4-.58 1.977 1.977 0 011.4.578l3.942 3.942a1.977 1.977 0 01.579 1.4 1.98 1.98 0 01-.578 1.4l-.758-.756.757.757-1.9 1.9-.024.024a4.957 4.957 0 01-3.584 1.337h-.019zm-9.5-7.2c3.3 3.273 7.124 5.09 9.5 5.06a2.856 2.856 0 002.074-.694c.01-.011.026-.028.05-.051l1.773-1.773-3.711-3.71-2.023 2.023-.007.008a1.77 1.77 0 01-1.251.515 1.757 1.757 0 01-1-.31 25.266 25.266 0 01-3.661-3.031 25.164 25.164 0 01-3.016-3.641 1.775 1.775 0 01-.323-1.02 1.77 1.77 0 01.518-1.253h.005l2.023-2.023-3.71-3.711-1.78 1.781-.034.033-.006.006a2.843 2.843 0 00-.7 2.077c-.03 2.388 1.8 6.232 5.1 9.544l.033.032.03.03.047.047c0 .005.026.025.065.062z"
          fill={props.color}
        />
      </g>
      <g data-name="Group 1336">
        <g data-name="Group 1334">
          <path
            data-name="Path 1290"
            d="M18.501 12.477a1.072 1.072 0 01-1.02-1.12c0-.09.006-.177.006-.261a5.1 5.1 0 00-1.5-3.624 5.1 5.1 0 00-3.392-1.5 1.071 1.071 0 01-1.023-1.118 1.072 1.072 0 011.118-1.023 7.245 7.245 0 014.811 2.12 7.247 7.247 0 012.128 5.14q0 .184-.009.361a1.071 1.071 0 01-1.069 1.021H18.5z"
            fill={props.color}
          />
        </g>
        <g data-name="Group 1335">
          <path
            data-name="Path 1291"
            d="M22.333 12.659a1.071 1.071 0 01-1.018-1.121c.008-.15.011-.3.011-.45a8.92 8.92 0 00-2.626-6.33 8.922 8.922 0 00-5.924-2.616 1.072 1.072 0 01-1.021-1.12 1.071 1.071 0 011.12-1.02 11.071 11.071 0 017.341 3.241 11.067 11.067 0 013.252 7.845q0 .276-.013.554a1.072 1.072 0 01-1.07 1.019h-.052z"
            fill={props.color}
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
