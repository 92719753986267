import * as React from "react";

function SvgComponent(props) {
  return (
    <svg data-name="Group 1532" xmlns="http://www.w3.org/2000/svg" width={23.175} height={22.317} viewBox="0 0 23.175 22.317" {...props}>
      <g data-name="Group 1530">
        <path
          data-name="Path 1357"
          d="M8.837 16.93a3.766 3.766 0 01-1.174-2.725 3.719 3.719 0 01.422-1.722v.007l2.7-5.309a.94.94 0 01.838-.514.941.941 0 01.839.514l2.7 5.309v-.007a3.728 3.728 0 01.422 1.722 3.769 3.769 0 01-1.174 2.725 4.02 4.02 0 01-2.786 1.109 4.017 4.017 0 01-2.785-1.109zm.921-3.586v.007a1.835 1.835 0 00-.209.854 1.878 1.878 0 00.594 1.367 2.134 2.134 0 001.483.586 2.136 2.136 0 001.483-.586 1.881 1.881 0 00.594-1.367 1.838 1.838 0 00-.21-.854l.835-.434.838-.427-.838.427-.835.434v-.007l-1.864-3.663-1.863 3.663z"
          fill={props.color}
        />
      </g>
      <g data-name="Group 1531">
        <path
          data-name="Path 1358"
          d="M11.553 22.317h-.025a12.526 12.526 0 01-8.07-2.908A9.472 9.472 0 010 12.22a9.147 9.147 0 011.936-5.585 1.208 1.208 0 01.428-.354 1.2 1.2 0 01.526-.12 1.611 1.611 0 01.843.267 4.342 4.342 0 01.546.411c.332.287.653.607.917.837.566-.865 1.158-1.831 1.743-2.771.713-1.144 1.415-2.248 2.086-3.118A6.536 6.536 0 0110.403.379a1.966 1.966 0 011.07-.377h.227a1.966 1.966 0 011.07.377 4.748 4.748 0 01.8.719 17.329 17.329 0 011.553 2.07c.935 1.41 1.925 3.092 2.852 4.508.252-.219.559-.523.875-.8a3.831 3.831 0 01.746-.536 1.5 1.5 0 01.686-.179 1.2 1.2 0 01.526.12 1.214 1.214 0 01.427.352 9.156 9.156 0 011.937 5.587 9.472 9.472 0 01-3.457 7.189 12.527 12.527 0 01-8.07 2.908h-.095zm-9.671-10.1a7.586 7.586 0 002.8 5.761 10.66 10.66 0 006.906 2.455 10.661 10.661 0 006.905-2.455 7.586 7.586 0 002.8-5.761 7.177 7.177 0 00-1.192-3.937c-.254.221-.565.529-.886.811a3.863 3.863 0 01-.748.536 1.509 1.509 0 01-.686.179 1.206 1.206 0 01-.563-.139 1.224 1.224 0 01-.435-.393c-.727-1.071-1.471-2.3-2.191-3.451a35.567 35.567 0 00-2.008-2.986 4.22 4.22 0 00-.916-.934l-.042-.023h-.078a.746.746 0 00-.087.052 2.918 2.918 0 00-.475.436 15.646 15.646 0 00-1.371 1.837C8.602 5.73 7.472 7.678 6.389 9.274a1.219 1.219 0 01-.434.393 1.2 1.2 0 01-.563.139 1.612 1.612 0 01-.842-.266 4.257 4.257 0 01-.548-.412c-.337-.291-.662-.615-.928-.846a7.174 7.174 0 00-1.192 3.936zm.837-4.206z"
          fill={props.color}
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
