import React from "react";
import styled from "styled-components";
import { ApplicationPaths } from "../api-authorization/ApiAuthorizationConstants";

// Components
import Backdrop from "../Elements/Backdrop";
import FullButton from "../Buttons/FullButton";

const Popup = ({actionSecond}) => {
  const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
  return (
    <Wrapper className="flexCenter">
      <Backdrop />
      <PopupDiv className="flexSpaceCenter flexColumn relative">
              <p className="textCenter">Bist du sicher?</p>
        <div className="flexNullCenter" style={{ marginTop: "40px" }}>
          <div style={{ width: "120px", marginRight: "5px" }}>
            <FullButton title="Logout" link={logoutPath} />
          </div>
          <div style={{ width: "120px", marginLeft: "5px" }}>
            <FullButton title="Abbrechen" grey action={actionSecond ? () => actionSecond() : null} />
          </div>
        </div>
      </PopupDiv>
    </Wrapper>
  );
};

export default Popup;

const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 99999;
  top: 0px;
  left: 0px;
`;
const PopupDiv = styled.div`
  background-color: #fff;
  z-index: 9999;
  padding: 60px 80px 30px 80px;
  border-radius: 10px;
  min-width: 320px;
  @media (max-width: 460px) {
    margin: 0 10px;
  }
`;
