import React from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { openMobile, openNotification } from "../../redux/preferenceSlice";
// Components
import Notification from "../Elements/Notification";
import Profile from "../Elements/Profile";
// Assets
import LogoImg from "../../assets/svg/logo";
import BurgerImg from "../../assets/svg/burger";

const NavbarTop = () => {
  const dispatch = useDispatch();

  return (
    <WrapperDiv className="flexSpaceCenter">
      <div className="flexSpaceCenter">
        <LogoWrapper className="flexCenter pointer">
          <LogoImg />
        </LogoWrapper>
        <BurgerWrapper className="flexCenter pointer" onClick={() => dispatch(openMobile())}>
          <BurgerImg />
        </BurgerWrapper>
      </div>
      <div className="flexNullCenter">
        <Notification action={() => dispatch(openNotification())}/>
        <Profile />
      </div>
    </WrapperDiv>
  );
};

export default NavbarTop;

const WrapperDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  width: 100%;
  height: 60px;
  background-color: #fafafa;
  border-bottom: 2px solid #eaeeee;
  padding: 0 5px;
`;
const LogoWrapper = styled.div`
  width: 60px;
  height: 60px;
`;
const BurgerWrapper = styled.button`
  width: 60px;
  min-width: 60px;
  height: 60px;
  border: 0px;
  outline: none;
  background-color: transparent;
`;
