import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { useSelector } from "react-redux";
// Components
import Backdrop from "../Elements/Backdrop";
import NotificationBox from "../Elements/NotificationBox";
// Assets
import BellIcon from "../../assets/svg/bell";
import ArrowUpFull from "../../assets/svg/arrows/arrowUpFull";
import NoNewNotification from "../../assets/svg/noNewNotification";

const Notification = ({action}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const addrDetail = useSelector((state) => state.user.addrDetail);
  return (
    <ExtraWrapper className="flexCenter">
      <Wrapper className="flexCenter pointer" onClick={action ? () => action() : () => setShowDropdown(!showDropdown)}>
        {addrDetail && addrDetail.dashboardState && addrDetail.dashboardState.notifications.length > 0 ? (
          <Counter className="flexCenter">
            <p className="font12 bold">{addrDetail && addrDetail.dashboardState && addrDetail.dashboardState.notifications.length}</p>
          </Counter>
        ) : null}
        <BellIcon width={21} height={23} />
      </Wrapper>
      {showDropdown ? (
        <>
          <DropdownBox className="flexNullCenter flexColumn">
            <Header className="flexSpaceCenter">
              <ArrowUpWrapper>
                <ArrowUpFull />
              </ArrowUpWrapper>
              <div className="flexSpaceCenter" style={{ width: "100%" }}>
                <p className="font15">Benachrichtigungen</p>
              </div>
            </Header>
            <Body>
              {addrDetail && addrDetail.dashboardState && addrDetail.dashboardState.notifications && addrDetail.dashboardState.notifications.length > 0 ? (
                addrDetail.dashboardState.notifications.map((item, index) => (
                    <NotificationBox key={index} energy={item.energyType} title={item.title} date={item.createdDate} changeType={item.changeType} />
                ))
              ) : (
                <div className="flexCenter flexColumn" style={{ paddingTop: "50%" }}>
                  <NoNewNotification />
                  <p className="font15" style={{ color: "#ECECEC", paddingTop: "30px" }}>
                    Keine neue Benachrichtigung
                  </p>
                </div>
              )}
            </Body>
          </DropdownBox>
          <Backdrop transparent action={() => setShowDropdown(false)} />
        </>
      ) : null}
    </ExtraWrapper>
  );
};

export default Notification;

const dropdownOpenAnimation = keyframes`
  0% { top: 20px; }
  100% { top: 62px; }
`;
const ExtraWrapper = styled.div`
  position: relative;
  height: 60px;
`;
const Wrapper = styled.button`
  background-color: transparent;
  height: 60px;
  width: 60px;
  outline: none;
  border: 0px;
  position: relative;
  margin: 0 5px;
  @media (max-width: 360px) {
    margin: 0px;
  }
`;
const Counter = styled.div`
  background-color: #ff4141;
  height: 25px;
  width: 25px;
  border: 2px solid #fafafa;
  border-radius: 50%;
  position: absolute;
  top: 5px;
  right: 5px;
  p {
    color: #fff;
  }
`;
const DropdownBox = styled.div`
  background-color: #fff;
  position: absolute;
  min-width: 350px;
  height: 500px;
  right: 0;
  padding: 0px 0px 15px 15px;
  border-radius: 0.6rem;
  border: 2px solid #eaeeee;
  -webkit-box-shadow: 0px 3px 12px 0px rgba(139, 139, 131, 0.16);
  -moz-box-shadow: 0px 3px 12px 0px rgba(139, 139, 131, 0.16);
  box-shadow: 0px 3px 12px 0px rgba(139, 139, 131, 0.16);
  z-index: 9999;
  animation-name: ${dropdownOpenAnimation};
  animation-duration: 0.3s;
  -webkit-animation: ${dropdownOpenAnimation} 0.3s forwards;
  animation: ${dropdownOpenAnimation} 0.3s forwards;
`;
const Header = styled.div`
  padding: 8px 0px;
  width: 100%;
`;
const Body = styled.div`
  width: 100%;
  overflow-y: auto;
  padding-right: 15px;
`;
const ArrowUpWrapper = styled.div`
  position: absolute;
  top: -10px;
  right: 15px;
`;

