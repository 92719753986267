import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={21.216} height={18} viewBox="0 0 21.216 18" {...props}>
      <g data-name="Group 1380" fill="none" stroke="#171727" strokeLinecap="round" strokeWidth={2}>
        <path data-name="Line 31" d="M1 1h19.216" />
        <path data-name="Line 32" d="M1 9h19.216" />
        <path data-name="Line 33" d="M1 17h19.216" />
      </g>
    </svg>
  );
}

export default SvgComponent;
