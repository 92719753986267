import * as React from "react";

function SvgComponent(props) {
  return (
    <svg data-name="Group 957" xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 18.974 20.733" {...props}>
      <g data-name="Group 814">
        <path
          data-name="Path 995"
          d="M3.021 16.923a.914.914 0 01-.642-.266.91.91 0 01-.266-.642V7.374A7.374 7.374 0 019.487 0a7.375 7.375 0 017.374 7.374v8.641a.9.9 0 01-.265.642.9.9 0 01-.642.266H3.021zm.907-1.815h11.118V7.422v-.048a5.567 5.567 0 00-5.559-5.559 5.566 5.566 0 00-5.559 5.55V15.108z"
          fill="#171727"
        />
      </g>
      <g data-name="Group 815">
        <path
          data-name="Path 996"
          d="M.907 16.924A.907.907 0 010 16.017a.907.907 0 01.907-.908h17.159a.908.908 0 01.908.908.908.908 0 01-.908.907H.907z"
          fill="#171727"
        />
      </g>
      <g data-name="Group 816">
        <path
          data-name="Path 997"
          d="M5.679 16.819a.908.908 0 01.908-.908.908.908 0 01.908.908 2.1 2.1 0 002.1 2.1 2.1 2.1 0 002.1-2.1.908.908 0 01.908-.908.908.908 0 01.908.908 3.915 3.915 0 01-3.914 3.914 3.915 3.915 0 01-3.914-3.914z"
          fill="#171727"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
