import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import Moment from "react-moment";
import { selectAddress, selectAccount } from "../redux/userSlice";
import {maskStr} from "../helpers/utils";
// Components
import SelectButton from "../components/Buttons/SelectButton";
import FullButton from "../components/Buttons/FullButton";
// Assets
import Mapicon from "../assets/svg/map";
import ProfileIcon from "../assets/svg/person";
import CalendarIcon from "../assets/svg/calendar";
import BankIcon from "../assets/svg/bank";
import PeopleIcon from "../assets/svg/people";
import PhoneIcon from "../assets/svg/phone";
import EnvelopeIcon from "../assets/svg/envelope";
// Banners
import BannerTop from "../assets/img/BannerTop.png";
import BannerBottom from "../assets/img/BannerBottom.png";

const Dashboard = () => {
  const dispatch = useDispatch();
  const sidebarOpen = useSelector((state) => state.preference.sidebarOpen);
  const accDetail = useSelector((state) => state.user.accDetail);
  const user = useSelector((state) => state.user);

  const selectAddressHandler = (index) => {
    dispatch(selectAddress(accDetail.addresses[index]));
  };

  const selectAccHandler = () => {
    dispatch(selectAccount(null));
    dispatch(selectAddress(null));
  }; 

  return (
    <div className={`safeArea animate ${sidebarOpen ? "sidebarOpen" : "sidebarClosed"}`}>
      <div className="container">
        <div className="row">
          <Header className="col-xs-12 col-md-12 flexSpaceCenter">
            <div>
              <h3 className="font40 bold" style={{ marginBottom: "10px" }}>
                Willkommen
              </h3>
              <p className="font18">
                zu deiner <span className="orangeColor">Energy Hero</span> &#220;bersicht
              </p>
            </div>
            {user && user.accounts && user.accounts.items && user.accounts.items.length > 1 ? (
              <div style={{ minWidth: "280px", marginTop: "15px" }}>
                <FullButton title="Anderes Konto ausw&#228;hlen" action={() => selectAccHandler()} />
              </div>
            ) : null}
          </Header>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-12 col-lg-8">
            <EmptyBox>
              <div className="flexNullCenter" style={{ marginBottom: "40px" }}>
                <Mapicon />
                <div style={{ marginLeft: "20px" }}>
                  <h4 className="font18 bold" style={{ marginBottom: "10px" }}>
                    Deine angemeldeten Adressen
                  </h4>
                  <p className="font14">Bitte w&#228;hlen Sie die jeweilige Adresse aus und Sie erhalten weitere Informationen.</p>
                </div>
              </div>
              {accDetail && accDetail.addresses && accDetail.addresses.length > 0
                ? accDetail.addresses.map((item, index) => (
                    <SelectButton
                      active={user.selectedAddress === item}
                      key={index}
                      index={index + 1}
                      address={item.name}
                      id={index}
                      action={selectAddressHandler}
                    />
                  ))
                : null}
              <ButtonWrapper>
                <FullButton href="https://anmeldung.energyhero.at/" title="+ Adresse hinzuf&#252;gen" />
              </ButtonWrapper>
            </EmptyBox>
            <InfoRowWrapper className="flexSpaceNull">
              <InfoBoxWrapper>
                <EmptyBoxHeader>
                  <HeaderBox className="flexNullCenter">
                    <AvatarCircle className="orangeColorBg flexCenter">
                      <ProfileIcon color="#fff" />
                    </AvatarCircle>
                    <div style={{ marginLeft: "30px" }}>
                      <h2 className="font18" style={{ marginBottom: "10px" }}>
                        {accDetail ? accDetail.name : null}
                      </h2>
                      <h3 className="font15">{accDetail ? accDetail.email : null}</h3>
                    </div>
                  </HeaderBox>
                  <div style={{ padding: "20px 0" }}>
                    <BoxRow className="flexNullCenter">
                      <div className="flexCenter" style={{ width: "10%", minWidth: "30px" }}>
                        <CalendarIcon />
                      </div>
                      <BoxRowTitle>
                        <p className="font15">Geburtsdatum:</p>
                      </BoxRowTitle>
                      <BoxRowSubtitle>
                        <p className="font15 bold">{accDetail ? <Moment format="DD.MM.YYYY">{accDetail.birthday}</Moment> : null}</p>
                      </BoxRowSubtitle>
                    </BoxRow>
                    <BoxRow className="flexNullCenter">
                      <div className="flexCenter" style={{ width: "10%", minWidth: "30px" }}>
                        <BankIcon />
                      </div>
                      <BoxRowTitle>
                        <p className="font15">IBAN:</p>
                      </BoxRowTitle>
                      <BoxRowSubtitle>
                        <p className="font15 bold">{accDetail ? maskStr(accDetail.iban) : null}</p>
                      </BoxRowSubtitle>
                    </BoxRow>
                    <BoxRow className="flexNullCenter">
                      <div className="flexCenter" style={{ width: "10%", minWidth: "30px" }}>
                        <PeopleIcon />
                      </div>
                      <BoxRowTitle>
                        <p className="font15">Friendscode:</p>
                      </BoxRowTitle>
                      <BoxRowSubtitle>
                        <p className="font15 bold">{accDetail ? accDetail.friendsCode : null}</p>
                      </BoxRowSubtitle>
                    </BoxRow>
                  </div>
                </EmptyBoxHeader>
              </InfoBoxWrapper>
              <InfoBoxWrapper>
                <EmptyBoxHeader>
                  <HeaderBox className="flexNullCenter">
                    <AvatarCircle className="orangeColorBg flexCenter">
                      <PhoneIcon color="#fff" />
                    </AvatarCircle>
                    <div style={{ marginLeft: "30px" }}>
                      <h2 className="font18">Hast du noch Fragen?</h2>
                    </div>
                  </HeaderBox>
                  <div style={{ padding: "20px 0" }}>
                    <BoxRow className="flexNullCenter">
                      <div className="flexCenter" style={{ width: "10%", minWidth: "30px" }}>
                        <EnvelopeIcon />
                      </div>
                      <BoxRowTitle>
                        <p className="font15">E-Mail:</p>
                      </BoxRowTitle>
                      <BoxRowSubtitle>
                        <MailToStyle href="mailto:hallo@energyhero.at" className="font15 bold animate">
                          hallo@energyhero.at
                        </MailToStyle>
                      </BoxRowSubtitle>
                    </BoxRow>
                    <BoxRow className="flexNullCenter">
                      <div className="flexCenter" style={{ width: "10%", minWidth: "30px" }}>
                        <PhoneIcon color="#404A4F" />
                      </div>
                      <BoxRowTitle>
                        <p className="font15">Telefon:</p>
                      </BoxRowTitle>
                      <BoxRowSubtitle>
                        <p className="font15 bold" style={{ lineHeight: "1.8rem" }}>
                            +43 (0) 720 2 720 20<br></br>Mo-Do 10:00 &mdash; 16:00 Uhr<br></br>Fr 10:00 &mdash; 13:00 Uhr
                        </p>
                      </BoxRowSubtitle>
                    </BoxRow>
                  </div>
                </EmptyBoxHeader>
              </InfoBoxWrapper>
            </InfoRowWrapper>
          </div>
          <div className="col-xs-12 col-md-12 col-lg-4">
            <BannerBox className="relative flexCenter">
              <img src={BannerTop} alt="Banner" />
            </BannerBox>
            <BannerBox className="relative flexCenter">
              <img src={BannerBottom} alt="Banner" />
            </BannerBox>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

const BannerBox = styled.div`
  background-color: #fff;
  border-radius: 0.8rem;
  border: 2px solid #eaeeee;
  margin-top: 30px;
  @media (max-width: 400px) {
    margin-top: 10px;
  }
  img {
    
    width: 100%;
    height: auto;
    border-radius: 0.8rem;
    background-position: center;
    background-size: cover;
  }
`;
const Header = styled.div`
  padding-bottom: 10px;
  @media (max-width: 859px) {
    padding-bottom: 0px;
  }
  @media (max-width: 630px) {
    text-align: center;
    flex-direction: column;
  }
`;
const EmptyBox = styled.div`
  background-color: #fff;
  border-radius: 0.8rem;
  border: 2px solid #eaeeee;
  padding: 30px 20px;
  margin-top: 30px;
  @media (max-width: 400px) {
    margin-top: 10px;
  }
`;
const EmptyBoxHeader = styled.div`
  background-color: #fff;
  border-radius: 0.8rem;
  border: 2px solid #eaeeee;
  margin-top: 30px;
  min-height: 320px;
  @media (max-width: 400px) {
    margin-top: 10px;
  }
`;
const ButtonWrapper = styled.div`
  width: 245px; 
  margin: 0 auto;
  margin-top: 20px;
`;
const HeaderBox = styled.div`
  background-color: #fafafa;
  width: 100%;
  padding: 30px;
  border-top-left-radius: 0.8rem;
  border-top-right-radius: 0.8rem;
`;
const AvatarCircle = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  min-width: 50px;
  min-height: 50px;
`;
const BoxRow = styled.div`
  padding: 15px 30px;
  @media (max-width: 400px) {
    flex-direction: column;
    text-align: center!important;
  }
`;
const InfoRowWrapper = styled.div`
  @media (max-width: 1580px) {
    flex-direction: column;
  }
`;
const InfoBoxWrapper = styled.div`
  width: 48.5%;
  @media (max-width: 1580px) {
    width: 100%;
  }
`;
const BoxRowTitle = styled.div`
  width: 40%;
  padding-left: 20px;
  @media (max-width: 400px) {
    width: 100%;
    padding: 5px 0px;
  }
`;
const BoxRowSubtitle = styled.div`
  width: 50%;
  @media (max-width: 400px) {
    width: 100%;
    padding: 5px 0px;
  }
`
const MailToStyle = styled.a`
  color: #171727;
  :hover {
    color: #f9a81a;
  }
`;
;