import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={27.492} height={25.463} viewBox="0 0 27.492 25.463" {...props}>
      <g data-name="Group 1590">
        <g data-name="Group 1573">
          <g data-name="Group 1571">
            <path
              data-name="Path 1385"
              d="M4.524 5.565a1.066 1.066 0 01-1.066-1.066 1.066 1.066 0 011.066-1.066h1.884a1.065 1.065 0 011.065 1.066 1.065 1.065 0 01-1.065 1.066H4.524z"
              fill="#404a4f"
            />
          </g>
          <g data-name="Group 1572">
            <path
              data-name="Path 1386"
              d="M9.943 5.565a1.065 1.065 0 01-1.066-1.066 1.066 1.066 0 011.066-1.066h1.884a1.066 1.066 0 011.066 1.066 1.066 1.066 0 01-1.066 1.066H9.943z"
              fill="#404a4f"
            />
          </g>
        </g>
        <g data-name="Group 1576">
          <g data-name="Group 1574">
            <path
              data-name="Path 1387"
              d="M4.524 8.816a1.066 1.066 0 01-1.066-1.065 1.066 1.066 0 011.066-1.067h1.884a1.066 1.066 0 011.066 1.067 1.066 1.066 0 01-1.066 1.065H4.524z"
              fill="#404a4f"
            />
          </g>
          <g data-name="Group 1575">
            <path
              data-name="Path 1388"
              d="M9.944 8.816a1.065 1.065 0 01-1.066-1.065 1.066 1.066 0 011.066-1.067h1.884a1.066 1.066 0 011.066 1.067 1.066 1.066 0 01-1.066 1.065H9.944z"
              fill="#404a4f"
            />
          </g>
        </g>
        <g data-name="Group 1579">
          <g data-name="Group 1577">
            <path
              data-name="Path 1389"
              d="M4.524 12.201a1.066 1.066 0 01-1.066-1.066 1.066 1.066 0 011.066-1.066h1.884a1.066 1.066 0 011.066 1.066 1.066 1.066 0 01-1.066 1.066H4.524z"
              fill="#404a4f"
            />
          </g>
          <g data-name="Group 1578">
            <path
              data-name="Path 1390"
              d="M9.943 12.201a1.066 1.066 0 01-1.066-1.066 1.066 1.066 0 011.066-1.066h1.884a1.065 1.065 0 011.066 1.066 1.066 1.066 0 01-1.066 1.066H9.943z"
              fill="#404a4f"
            />
          </g>
        </g>
        <g data-name="Group 1583">
          <g data-name="Group 1580">
            <path
              data-name="Path 1391"
              d="M20.009 15.48a1.066 1.066 0 01-1.066-1.066 1.066 1.066 0 011.066-1.066h1.886a1.066 1.066 0 011.065 1.066 1.066 1.066 0 01-1.065 1.066h-1.884z"
              fill="#404a4f"
            />
          </g>
          <g data-name="Group 1581">
            <path
              data-name="Path 1392"
              d="M20.009 18.73a1.066 1.066 0 01-1.066-1.066 1.066 1.066 0 011.066-1.066h1.885a1.066 1.066 0 011.066 1.066 1.067 1.067 0 01-1.066 1.066h-1.883z"
              fill="#404a4f"
            />
          </g>
          <g data-name="Group 1582">
            <path
              data-name="Path 1393"
              d="M20.009 22.107a1.066 1.066 0 01-1.066-1.066 1.066 1.066 0 011.066-1.066h1.886a1.065 1.065 0 011.066 1.066 1.066 1.066 0 01-1.066 1.066h-1.883z"
              fill="#404a4f"
            />
          </g>
        </g>
        <g data-name="Group 1586">
          <g data-name="Group 1584">
            <path
              data-name="Path 1394"
              d="M4.524 15.449a1.066 1.066 0 01-1.066-1.066 1.066 1.066 0 011.066-1.066h1.885a1.066 1.066 0 011.066 1.066 1.067 1.067 0 01-1.066 1.066H4.524z"
              fill="#404a4f"
            />
          </g>
          <g data-name="Group 1585">
            <path
              data-name="Path 1395"
              d="M9.944 15.449a1.066 1.066 0 01-1.066-1.066 1.066 1.066 0 011.066-1.066h1.884a1.066 1.066 0 011.066 1.066 1.067 1.067 0 01-1.066 1.066H9.944z"
              fill="#404a4f"
            />
          </g>
        </g>
        <g data-name="Group 1587">
          <path
            data-name="Path 1396"
            d="M5.952 25.465a1.069 1.069 0 01-.753-.312 1.071 1.071 0 01-.313-.753v-6.022a1.073 1.073 0 01.312-.754 1.072 1.072 0 01.754-.313h4.3a1.074 1.074 0 01.753.313 1.07 1.07 0 01.313.754V24.4a1.071 1.071 0 01-.313.753 1.071 1.071 0 01-.753.313h-4.3zm4.3-1.066zm-3.236-1.067h2.17v-3.891H7.017v3.891z"
            fill="#404a4f"
          />
        </g>
        <g data-name="Group 1588">
          <path
            data-name="Path 1397"
            d="M1.066 25.463a1.072 1.072 0 01-.753-.312A1.071 1.071 0 010 24.397V1.067A1.068 1.068 0 01.313.314a1.068 1.068 0 01.753-.313h14.07a1.073 1.073 0 01.754.313 1.071 1.071 0 01.312.753v23.33a1.058 1.058 0 01-.313.753 1.055 1.055 0 01-.753.313H1.066zm14.074-1.066zM2.132 23.331h11.942v-21.2H2.136v21.2z"
            fill="#404a4f"
          />
        </g>
        <g data-name="Group 1589">
          <path
            data-name="Path 1398"
            d="M15.14 25.463a1.071 1.071 0 01-.754-.312 1.074 1.074 0 01-.312-.753V10.736a1.073 1.073 0 01.312-.754 1.074 1.074 0 01.754-.313h11.286a1.075 1.075 0 01.754.313 1.073 1.073 0 01.312.754v13.657a1.061 1.061 0 01-.312.753 1.061 1.061 0 01-.754.313H15.14zm10.22-2.132v-11.53h-9.154v11.53z"
            fill="#404a4f"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
