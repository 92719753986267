import React, { useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { selectAccount, togglePopup } from "../redux/userSlice";
// Components
import SelectButton from "../components/Buttons/SelectButton";
import FullButton from "../components/Buttons/FullButton";
// Assets
import LogoBigDark from "../assets/svg/logoBigDark";
import LinesBg from "../assets/svg/lines.svg"

const AccountSelect = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const getYear = () => {
    let d = new Date();
    return d.getFullYear();
  };

  const selectAccHandler = (id) => {
    dispatch(selectAccount(id));
  }; 

  useEffect(() => {
    if (user.accounts && user.accounts.items.length === 1) {
      dispatch(selectAccount(user.accounts && user.accounts.items[0].id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.accounts]);

  return (
    <PageWrapper>
      <InnerWrapper className="flexColumn flexSpaceCenter">
        <InfoWrapper className="flexCenter">
          <div style={{ width: "100%", height: "100%", padding: "15% 0 0 0" }}>
            <LogoBigDark />
            <h1 className="font40" style={{ padding: "20px 0" }}>
                Willkommen zurück
            </h1>
            <p className="font15" style={{ paddingBottom: "30px" }}>
                Bitte wählen Sie Ihr Konto
            </p>
            {user && user.accounts && user.accounts.items
              ? user.accounts.items.map((item, index) => (
                  <SelectButton account key={index} index={index + 1} address={item.address} id={item.id} action={selectAccHandler} />
                ))
              : null}
          </div>
        </InfoWrapper>
        <FooterWrapper className="textCenter">
          <div style={{ width: "100%", maxWidth: '300px', margin: '15px auto' }}>
            <FullButton title="Logout" action={() => dispatch(togglePopup(true))} />
          </div>
          <p className="font11"> © {getYear()} All Rights Reserved</p>
        </FooterWrapper>
      </InnerWrapper>
      <BgLines className="flexCenter">
        <img src={LinesBg} alt="background" />
      </BgLines>
    </PageWrapper>
  );
};

export default AccountSelect;

const PageWrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0; 
  left: 0;
  background-color: #fafafa;
  z-index: 99999;
`;
const InnerWrapper = styled.div`
  width: 100%;
  max-width: 720px;
  height: 100%;
  margin: 0 auto;
  text-align: center;
  padding: 0 30px;
  z-index: 99;
  position: relative;
  background-color: #fff;
`;
const InfoWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;
const FooterWrapper = styled.div`
  width: 100%;
  padding-bottom: 30px;
`;
const BgLines = styled.div`
  width: 100%;
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1;
`;
