import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { togglePopup } from "../../redux/userSlice";
// Components
import Backdrop from "../Elements/Backdrop";
import FullButton from "../Buttons/FullButton";
// Assets
import PersonIcon from "../../assets/svg/person";
import ArrowUpFull from "../../assets/svg/arrows/arrowUpFull";

const Profile = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dispatch = useDispatch();
  const accDetail = useSelector((state) => state.user.accDetail);

  return (
    <ExtraWrapper className="flexCenter">
      <Wrapper className="flexCenter pointer" onClick={() => setShowDropdown(!showDropdown)}>
        <PersonIcon color="#171727" />
      </Wrapper>
      {showDropdown ? (
        <>
          <DropdownBox className="flexNullCenter flexColumn">
            <ArrowUpWrapper>
              <ArrowUpFull />
            </ArrowUpWrapper>
            <AvatarImg className="flexCenter">
              <PersonIcon color="#fff" />
            </AvatarImg>
            <p className="font15 bold">{accDetail ? accDetail.name : null}</p>
            <p className="font13" style={{ margin: "10px 0" }}>
              {accDetail ? accDetail.email : null}
            </p>
            <div style={{ margin: "5px 0", width: "100%" }}>
              <FullButton title="Passwort &#228;ndern" href="/Identity/Account/Manage/changepassword" grey />
            </div>
            <div style={{ margin: "5px 0", width: "100%" }}>
              <FullButton title="Logout" action={() => dispatch(togglePopup(true))} />
            </div>
          </DropdownBox>
          <Backdrop transparent action={() => setShowDropdown(false)} />
        </>
      ) : null}
    </ExtraWrapper>
  );
};

export default Profile;

const dropdownOpenAnimation = keyframes`
  0% { top: 20px; }
  100% { top: 62px; }
`;
const ExtraWrapper = styled.div`
  position: relative;
  height: 60px;
`;
const Wrapper = styled.button`
  background-color: transparent;
  height: 60px;
  width: 60px;
  outline: none;
  border: 0px;
  position: relative;
  margin: 0 5px;
  @media (max-width: 360px) {
    margin: 0px;
  }
`;
const DropdownBox = styled.div`
  background-color: #fff;
  position: absolute;
  min-width: 230px;
  right: 0;
  padding: 15px;
  border-radius: 0.6rem;
  border: 2px solid #eaeeee;
  -webkit-box-shadow: 0px 3px 12px 0px rgba(139, 139, 131, 0.16);
  -moz-box-shadow: 0px 3px 12px 0px rgba(139, 139, 131, 0.16);
  box-shadow: 0px 3px 12px 0px rgba(139, 139, 131, 0.16);
  z-index: 9999;
  animation-name: ${dropdownOpenAnimation};
  animation-duration: 0.3s;
  -webkit-animation: ${dropdownOpenAnimation} 0.3s forwards;
  animation: ${dropdownOpenAnimation} 0.3s forwards;
`;
const ArrowUpWrapper = styled.div`
  position: absolute;
  top: -10px;
  right: 15px;
`;
const AvatarImg = styled.div`
  background-color: #f9a81a;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 15px;
`;
