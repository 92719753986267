import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { postSaveChanges, closeSuccessModal } from "../redux/userSlice";
import { maskStr } from "../helpers/utils";
// Components
import ProductSelect from "../components/Buttons/ProductSelect";
import CheckBox from "../components/Inputs/Checkbox";
import ProgressPoint from "../components/Elements/ProgressPoint";
import FullButton from "../components/Buttons/FullButton";
import InputLabel from "../components/Inputs/InputLabel";
import ModalSuccess from "../components/Elements/ModalSuccess";
// Assets
import EmptyState from "../assets/svg/EmptyState";
import DocumentIcon from "../assets/svg/document";
import BuildingIcon from "../assets/svg/building";
import TagIcon from "../assets/svg/tag";
import SavingsIcon from "../assets/svg/savings";
import InfoIcon from "../assets/svg/info";
import ArrowUpFull from "../assets/svg/arrows/arrowUpFull";
import ArrowsIntersect from "../assets/svg/arowsIntersect";
import ElectricSymbol from "../assets/svg/electricSymbol";
import MeterIcon from "../assets/svg/meter";

const Strom = () => {
  const dispatch = useDispatch()
  const sidebarOpen = useSelector((state) => state.preference.sidebarOpen);
  const user = useSelector((state) => state.user);
  const [showDropdownInfo, setShowDropdownInfo] = useState(false);
  const [showStatusDropdownInfo, setShowStatusDropdownInfo] = useState(false);
  const addressDetail = useSelector((state) => state.user.addrDetail);
  const [energyData, setEnergyData] = useState(null);
  // PREFERENCES
  const [nkDiscount, setNkDiscount] = useState(false);
  const [greenElectricity, setGreenElectricity] = useState(false);
  const [electricityAustria, setElectricityAustria] = useState(false);
  const [totalBill, setTotalBill] = useState(false);
  // CONSUMPTION
  const [consumptionValue, setConsumptionValue] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [iban, setIban] = useState("");

  useEffect(() => {
    if (addressDetail && addressDetail.energyProviderDetails && addressDetail.energyProviderDetails !== "undefined") {
      const energyMatch = addressDetail.energyProviderDetails.find((obj) =>
        obj.energyType && obj.energyType !== "undefined" ? obj.energyType.toLowerCase() === "electricity" : null
      );
      setEnergyData(energyMatch);
    }
  }, [addressDetail]);

  useEffect(() => {
    setNkDiscount(energyData && energyData.tariffDiscount ? energyData.tariffDiscount : false);
    setGreenElectricity(energyData && energyData.ecoElectricity ? energyData.ecoElectricity : false);
    setElectricityAustria(energyData && energyData.electricityFromAUS ? energyData.electricityFromAUS : false);
    setTotalBill(energyData && energyData.totalInvoice ? energyData.totalInvoice : false);
    setConsumptionValue(energyData && energyData.annualConsumption ? energyData.annualConsumption : "");
  }, [energyData]);

  const saveHandler = () => {
      let userMadeChanges = false;
      let data = {
        AccountId: user.selectedAccount,
        AddressId: user.selectedAddress.id,
        AddressName: user.selectedAddress.name,
        ContractId: energyData.contractId,
        EnergyProvider: energyData.energyProvider,
        EnergyProviderId: energyData.energyProviderId,
        EnergyType: energyData.energyType,
        ExpectedSavings: energyData.expectedSavings,
        MeterNumber: energyData.meterNumber,
        ProductId: energyData.productId,
        ProgressBarStatus: energyData.progressBarStatus,
        ShippingAddress: energyData.shippingAddress,
        Status: energyData.status,
        Tariff: energyData.tariff,
      };
      if (nkDiscount !== energyData.tariffDiscount) {
        data.NkDiscountNew = nkDiscount;
        data.NkDiscountOld = energyData.tariffDiscount;
        userMadeChanges = true;
      } else {
        data.NkDiscountNew = energyData.tariffDiscount;
        data.NkDiscountOld = energyData.tariffDiscount;
      }
      if (greenElectricity !== energyData.ecoElectricity) {
        data.GreenElectricityNew = greenElectricity;
        data.GreenElectricityOld = energyData.ecoElectricity;
        userMadeChanges = true;
      } else {
        data.GreenElectricityNew = energyData.ecoElectricity;
        data.GreenElectricityOld = energyData.ecoElectricity;
      }
      if (electricityAustria !== energyData.electricityFromAUS) {
        data.ElectricityFromAustriaNew = electricityAustria;
        data.ElectricityFromAustriaOld = energyData.electricityFromAUS;
        userMadeChanges = true;
      } else {
        data.ElectricityFromAustriaNew = energyData.electricityFromAUS;
        data.ElectricityFromAustriaOld = energyData.electricityFromAUS;
      }
      if (totalBill !== energyData.totalInvoice) {
        data.TotalBillNew = totalBill;
        data.TotalBillOld = energyData.totalInvoice;
        userMadeChanges = true;
      } else {
        data.TotalBillNew = energyData.totalInvoice;
        data.TotalBillOld = energyData.totalInvoice;
      }
      if (consumptionValue !== energyData.annualConsumption && consumptionValue.length > 0) {
        data.ConsumptionNew = parseInt(consumptionValue);
        data.ConsumptionOld = energyData.annualConsumption;
        userMadeChanges = true;
      } else {
        data.ConsumptionNew = energyData.annualConsumption;
        data.ConsumptionOld = energyData.annualConsumption;
      }
      if (billingAddress !== addressDetail.billingAddress && billingAddress.replace(/ /g, "") !== "") {
        data.BillingAddressNew = billingAddress;
        data.BillingAddressOld = addressDetail.billingAddress;
        userMadeChanges = true;
      } else {
        data.BillingAddressNew = addressDetail.billingAddress;
        data.BillingAddressOld = addressDetail.billingAddress;
      }
      if (iban !== addressDetail.iban && iban.replace(/ /g, "") !== "") {
        data.IbanNew = iban;
        data.IbanOld = addressDetail.iban;
        userMadeChanges = true;
      }else {
        data.IbanNew = addressDetail.iban;
        data.IbanOld = addressDetail.iban;
      }
      if (userMadeChanges) {
        dispatch(postSaveChanges(data))
      }
  }
  return (
    <div className={`safeArea animate ${sidebarOpen ? "sidebarOpen" : "sidebarClosed"}`}>
      {user?.savedChanges && user?.addrDetail?.dashboardState?.invalidIban === false ? <ModalSuccess action={() => dispatch(closeSuccessModal())} /> : null}
      {user?.savedChanges && user?.addrDetail?.dashboardState?.invalidIban ? <ModalSuccess error action={() => dispatch(closeSuccessModal())} /> : null}
      <div className="container">
        <div className="row">
          <Header className="col-xs-12 col-md-12 flexSpaceCenter">
            <HeaderAddressWrapper>
              <h3 className="font40 bold" style={{ marginBottom: "15px" }}>
                Lieferadresse:
              </h3>
              <p className="font15">
                {user && user.accDetail && user.accDetail.name ? user.accDetail.name : null} -{" "}
                {user && user.selectedAddress && user.selectedAddress.name ? user.selectedAddress.name : null}
              </p>
            </HeaderAddressWrapper>
            <div className="flexNullCenter">
              <ProductSelect title="Strom" active to="/strom" />
              <ProductSelect title="Gas" to="/gas" />
            </div>
          </Header>
        </div>
        {energyData ? (
          <>
            <div className="row">
              <div className="col-xs-12 col-md-12 col-lg-4">
                <EmptyBoxHeader>
                  <HeaderBox className="flexNullCenter">
                    <AvatarCircle className="orangeColorBg flexCenter">
                      <DocumentIcon />
                    </AvatarCircle>
                    <div style={{ marginLeft: "30px" }}>
                      <h2 className="font18">Derzeitiger Vertrag</h2>
                    </div>
                  </HeaderBox>
                  <div style={{ padding: "20px 0 0 0" }}>
                    <BoxRow className="flexNullCenter">
                      <div className="flexCenter" style={{ width: "10%", minWidth: "35px" }}>
                        <BuildingIcon />
                      </div>
                      <BoxRowTitle>
                        <p className="font15">Anbieter:</p>
                        <p className="font15 bold">{energyData.energyProvider ? energyData.energyProvider : "No Data"}</p>
                      </BoxRowTitle>
                    </BoxRow>
                    <BoxRow className="flexNullCenter">
                      <div className="flexCenter" style={{ width: "10%", minWidth: "35px" }}>
                        <TagIcon />
                      </div>
                      <BoxRowTitle>
                        <p className="font15">Tarif:</p>
                        <p className="font15 bold">{energyData.tariff ? energyData.tariff : "No Data"}</p>
                      </BoxRowTitle>
                    </BoxRow>
                    <BoxRow className="flexNullCenter">
                      <div className="flexCenter" style={{ width: "10%", minWidth: "35px" }}>
                        <SavingsIcon />
                      </div>
                      <BoxRowTitle>
                        <p className="font15">Voraussichtliche Ersparnis:</p>
                        <ExpectedSavings className="lightColorBg">
                          <h1 className="font30 bold orangeColor">{energyData.expectedSavings ? energyData.expectedSavings + " €" : "No Data"}</h1>
                          <p className="font11" style={{ marginTop: "5px" }}>
                            (exkl. Servicegebühr)
                          </p>
                          <InfoBtn className="animate pointer" onMouseEnter={() => setShowDropdownInfo(true)} onMouseLeave={() => setShowDropdownInfo(false)}>
                            <InfoIcon />
                            {showDropdownInfo ? (
                              <DropdownBox className="flexNullCenter flexColumn">
                                <ArrowUpWrapper>
                                  <ArrowUpFull />
                                </ArrowUpWrapper>
                                <DropdownP className="font11 textLeft">
                                  Berechnung auf Basis aktueller Tarifinformationen. Energy Hero übernimmt keine Gewähr für Tarifanpassungen seitens der Anbieter im Zeitraum zwischen Vorabberechnung und dem tatsächlichen Anbieterwechsel. Die genannte Ersparnis bezieht sich auf deinen letzten Energy Hero Anbieterwechsel.
                                </DropdownP>
                              </DropdownBox>
                            ) : null}
                          </InfoBtn>
                        </ExpectedSavings>
                      </BoxRowTitle>
                    </BoxRow>
                  </div>
                </EmptyBoxHeader>
                <EmptyBoxHeader>
                  <HeaderBox className="flexNullCenter">
                    <AvatarCircle className="orangeColorBg flexCenter">
                      <DocumentIcon />
                    </AvatarCircle>
                    <div style={{ marginLeft: "30px" }}>
                      <h2 className="font18">Deine Präferenzen</h2>
                    </div>
                  </HeaderBox>
                  <div style={{ padding: "20px 0 0 0" }}>
                    <BoxRow className="flexNullCenter">
                      <div className="flexCenter" style={{ width: "10%", minWidth: "30px" }}>
                        <CheckBox
                          disabled={
                            addressDetail && addressDetail.dashboardState && addressDetail.dashboardState.isElectricityNkDiscountDisabled
                              ? addressDetail.dashboardState.isElectricityNkDiscountDisabled
                              : null
                          }
                          checked={nkDiscount}
                          action={() => setNkDiscount(!nkDiscount)}
                        />
                      </div>
                      <BoxRowTitle>
                        <p className="font15 bold">Tarife ohne Neukundenrabatte</p>
                      </BoxRowTitle>
                    </BoxRow>
                    <BoxRow className="flexNullCenter">
                      <div className="flexCenter" style={{ width: "10%", minWidth: "30px" }}>
                        <CheckBox
                          disabled={
                            addressDetail && addressDetail.dashboardState && addressDetail.dashboardState.isElectricityGreenElectricityDisabled
                              ? addressDetail.dashboardState.isElectricityGreenElectricityDisabled
                              : null
                          }
                          checked={greenElectricity}
                          action={() => setGreenElectricity(!greenElectricity)}
                        />
                      </div>
                      <BoxRowTitle>
                        <p className="font15 bold">&#214;kostrom</p>
                      </BoxRowTitle>
                    </BoxRow>
                    <BoxRow className="flexNullCenter">
                      <div className="flexCenter" style={{ width: "10%", minWidth: "30px" }}>
                        <CheckBox
                          disabled={
                            addressDetail && addressDetail.dashboardState && addressDetail.dashboardState.isElectricityFromAustriaDisabled
                              ? addressDetail.dashboardState.isElectricityFromAustriaDisabled
                              : null
                          }
                          checked={electricityAustria}
                          action={() => setElectricityAustria(!electricityAustria)}
                        />
                      </div>
                      <BoxRowTitle>
                        <p className="font15 bold">Strom aus AT</p>
                      </BoxRowTitle>
                    </BoxRow>
                    <BoxRow className="flexNullCenter">
                      <div className="flexCenter" style={{ width: "10%", minWidth: "30px" }}>
                        <CheckBox
                          disabled={
                            addressDetail && addressDetail.dashboardState && addressDetail.dashboardState.isElectricityTotalBillDisabled
                              ? addressDetail.dashboardState.isElectricityTotalBillDisabled
                              : null
                          }
                          checked={totalBill}
                          action={() => setTotalBill(!totalBill)}
                        />
                      </div>
                      <BoxRowTitle>
                        <p className="font15 bold">Gesamtrechnung</p>
                      </BoxRowTitle>
                    </BoxRow>
                  </div>
                </EmptyBoxHeader>
              </div>
              <div className="col-xs-12 col-md-12 col-lg-8">
                <EmptyBox>
                  <ProgressContainer>
                    <ProgressIcon className="flexNullCenter">
                      <ArrowsIntersect />
                      <div style={{ marginLeft: "30px" }}>
                        <h4 className="font18 bold">Aktueller Wechselstatus</h4>
                        <div className="relative flexNullCenter">
                          <p className="font14">
                            {energyData.extendedStatus && energyData.extendedStatus.description ? energyData.extendedStatus.description : null}
                          </p>
                          <InfoBtn2
                            className="animate pointer"
                            onMouseEnter={() => setShowStatusDropdownInfo(true)}
                            onMouseLeave={() => setShowStatusDropdownInfo(false)}
                          >
                            <InfoIcon />
                            {showStatusDropdownInfo ? (
                              <DropdownBox className="flexNullCenter flexColumn">
                                <ArrowUpWrapper>
                                  <ArrowUpFull />
                                </ArrowUpWrapper>
                                <DropdownP className="font13 textLeft">
                                  {energyData.extendedStatus && energyData.extendedStatus.info ? energyData.extendedStatus.info : null}
                                </DropdownP>
                              </DropdownBox>
                            ) : null}
                          </InfoBtn2>
                        </div>
                      </div>
                    </ProgressIcon>
                    <ProgressWrapper className="flexNullCenter" style={{ display: "flex", alignItems: "flex-start", width: "100%" }}>
                      {energyData.progressBarStatuses
                        ? energyData.progressBarStatuses.map((item, index) => (
                            <ProgressPoint
                              key={index}
                              active={item.checked}
                              unactive={!item.checked}
                              lineLeftActive={index !== 0 && item.checked}
                              lineRightActive={
                                index !== energyData.progressBarStatuses.length &&
                                energyData.progressBarStatuses[index + 1] &&
                                energyData.progressBarStatuses[index + 1].checked
                              }
                              lineLeft={index !== 0}
                              lineRight={index !== energyData.progressBarStatuses.length - 1}
                              title={item.description}
                              current={
                                (item.checked && !energyData.progressBarStatuses[index + 1]) ||
                                (energyData.progressBarStatuses[index + 1] && !energyData.progressBarStatuses[index + 1].checked)
                              }
                            />
                          ))
                        : null}
                    </ProgressWrapper>
                  </ProgressContainer>
                </EmptyBox>
                <EmptyBox>
                  <div className="flexNullCenter" style={{ marginBottom: "19px" }}>
                    <AvatarCircle className="orangeColorBg flexCenter">
                      <ElectricSymbol />
                    </AvatarCircle>
                    <div style={{ marginLeft: "20px" }}>
                      <h4 className="font18 bold" style={{ marginBottom: "10px" }}>
                        Verbrauch &#228;ndern
                      </h4>
                      <p className="font14">
                        Bitte gib uns Bescheid, wenn sich dein Verbrauch &#228;ndert, damit wir immer den besten Tarif f&#252;r deine Bed&#252;rfnisse finden k&#246;nnen. Bitte beachte, dass deine Verbrauchsdaten erst beim n&#228;chsten Wechsel ber&#252;cksichtigt werden k&#246;nnen.
                      </p>
                    </div>
                  </div>
                  <BigInputWrapper>
                    <WrapperDiv className="flexNullCenter">
                      <InputStyle
                        disabled={
                          addressDetail && addressDetail.dashboardState && addressDetail.dashboardState.isElectricityConsumptionDisabled
                            ? addressDetail.dashboardState.isElectricityConsumptionDisabled
                            : null
                        }
                        type="number"
                        onChange={(e) => setConsumptionValue(e.target.value)}
                        value={consumptionValue}
                        placeholder={energyData.annualConsumption ? energyData.annualConsumption : "No Data"}
                        style={
                          addressDetail && addressDetail.dashboardState && addressDetail.dashboardState.isElectricityConsumptionDisabled
                            ? { cursor: "not-allowed" }
                            : null
                        }
                      />
                      <UnitStyle className="flexCenter">
                        <p className="font15">kWh</p>
                      </UnitStyle>
                    </WrapperDiv>
                  </BigInputWrapper>
                  <MeterWrapper className="flexNullCenter">
                    <div style={{ margin: "0 12px" }}>
                      <MeterIcon />
                    </div>
                    <p className="font15" style={{ margin: "0 25px" }}>
                      Zählpunktbezeichnung:
                    </p>
                    <p className="font15 bold">{energyData.meterNumber ? energyData.meterNumber : "No Data"}</p>
                  </MeterWrapper>
                </EmptyBox>
                <EmptyBox>
                  <AddressHeaderWrapper className="flexNullCenter">
                    <AvatarCircle className="orangeColorBg flexCenter">
                      <DocumentIcon />
                    </AvatarCircle>
                    <div style={{ marginLeft: "20px" }}>
                      <h4 className="font18 bold" style={{ marginBottom: "10px" }}>
                        Zahlungsinformationen
                      </h4>
                      <p className="font14">
                        Bitte beachte, dass du deine Zahlungsinformationen auch deinem Energieanbieter und Netzbetreiber bekannt geben musst.{" "}
                      </p>
                    </div>
                  </AddressHeaderWrapper>
                  <AddressWrapper className="flexSpaceCenter">
                    <div className="flexNullCenter">
                      <div style={{ margin: "0 12px" }}>
                        <BuildingIcon />
                      </div>
                      <div style={{ margin: "0 30px", minWidth: "160px" }}>
                        <p className="font15">Rechnungsadresse:</p>
                        <p className="font15 bold">{addressDetail && addressDetail.billingAddress ? addressDetail.billingAddress : "No Data"}</p>
                      </div>
                    </div>
                    <div style={{ width: "100%", minWidth: "160px", maxWidth: "600px" }}>
                      <InputLabel
                        disabled={
                          addressDetail && addressDetail.dashboardState && addressDetail.dashboardState.isElectricityBillingAddressDisabled
                            ? addressDetail.dashboardState.isElectricityBillingAddressDisabled
                            : null
                        }
                        placeholder="Neue Adresse hinzuf&#252;gen"
                        value={billingAddress}
                        setValue={setBillingAddress}
                      />
                    </div>
                  </AddressWrapper>
                  <AddressWrapper className="flexSpaceCenter">
                    <div className="flexNullCenter">
                      <div style={{ margin: "0 7px" }}>
                        <p className="font15 bold">IBAN</p>
                      </div>
                      <div style={{ margin: "0 30px", minWidth: "160px" }}>
                        <p className="font15">IBAN:</p>
                        <p className="font15 bold">{addressDetail && addressDetail.iban ? maskStr(addressDetail.iban) : "No Data"}</p>
                      </div>
                    </div>
                    <div style={{ width: "100%", minWidth: "160px", maxWidth: "600px" }}>
                      <InputLabel
                        disabled={
                          addressDetail && addressDetail.dashboardState && addressDetail.dashboardState.isElectricityIbanDisabled
                            ? addressDetail.dashboardState.isElectricityIbanDisabled
                            : null
                        }
                        placeholder="Neue IBAN hinzuf&#252;gen"
                        value={iban}
                        setValue={setIban}
                      />
                    </div>
                  </AddressWrapper>
                </EmptyBox>
              </div>
            </div>
            <div className="row">
              <div style={{ margin: "20px auto 0 auto", width: "250px" }}>
                <FullButton title="&#196;nderungen speichern" action={saveHandler} />
              </div>
            </div>
          </>
        ) : (
          <div style={{ width: "100%", height: "50vh" }} className="flexCenter flexColumn">
            <EmptyState />
            <p style={{ marginTop: "30px" }}>Kein Strom-Wechsel gefunden</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Strom;

const dropdownOpenAnimation = keyframes`
  0% { top: 0px; }
  100% { top: 40px; }
`;
const Header = styled.div`
  padding-bottom: 10px;
  @media (max-width: 859px) {
    padding-bottom: 0px;
  }
  @media (max-width: 660px) {
    flex-direction: column;
  }
`;
const HeaderAddressWrapper = styled.div`
  @media (max-width: 660px) {
    padding-bottom: 20px;
  }
`;
const EmptyBox = styled.div`
  background-color: #fff;
  border-radius: 0.8rem;
  border: 2px solid #eaeeee;
  padding: 30px 20px;
  margin-top: 30px;
  @media (max-width: 400px) {
    margin-top: 10px;
  }
`;
const EmptyBoxHeader = styled.div`
  background-color: #fff;
  border-radius: 0.8rem;
  border: 2px solid #eaeeee;
  margin-top: 30px;
  padding-bottom: 10px;
  @media (max-width: 400px) {
    margin-top: 10px;
  }
`;
const HeaderBox = styled.div`
  background-color: #fafafa;
  width: 100%;
  padding: 20px 30px;
  border-top-left-radius: 0.8rem;
  border-top-right-radius: 0.8rem;
`;
const AvatarCircle = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  min-width: 50px;
  min-height: 50px;
`;
const BoxRow = styled.div`
  padding: 10px 30px;
  @media (max-width: 400px) {
    flex-direction: column;
    text-align: center !important;
  }
`;
const BoxRowTitle = styled.div`
  width: 90%;
  padding-left: 20px;
  @media (max-width: 660px) {
    padding: 10px 0 0 0px;
  }
`;
const ExpectedSavings = styled.div`
  margin: 10px 0;
  padding: 15px 60px 15px 30px;
  border-radius: 0.7rem;
  float: left;
  position: relative;
  @media (max-width: 400px) {
    float: none;
  }
`;
const InfoBtn = styled.button`
  background-color: transparent;
  border: 0px;
  outline: none;
  padding: 10px;
  position: absolute;
  top: 20px;
  right: 10px;
`;
const InfoBtn2 = styled.button`
  background-color: transparent;
  border: 0px;
  outline: none;
  padding: 10px;
  right: 10px;
  margin-top: 3px;
`;
const DropdownBox = styled.div`
  background-color: #fff;
  position: absolute;
  min-width: 230px;
  right: 0;
  padding: 15px;
  border-radius: 0.6rem;
  border: 2px solid #eaeeee;
  -webkit-box-shadow: 0px 3px 12px 0px rgba(139, 139, 131, 0.16);
  -moz-box-shadow: 0px 3px 12px 0px rgba(139, 139, 131, 0.16);
  box-shadow: 0px 3px 12px 0px rgba(139, 139, 131, 0.16);
  z-index: 9999;
  animation-name: ${dropdownOpenAnimation};
  animation-duration: 0.3s;
  -webkit-animation: ${dropdownOpenAnimation} 0.3s forwards;
  animation: ${dropdownOpenAnimation} 0.3s forwards;
`;
const ArrowUpWrapper = styled.div`
  position: absolute;
  top: -10px;
  right: 0px;
`;
const DropdownP = styled.p`
  line-height: 1rem;
  color: #5C5C67;
`;
const ProgressWrapper = styled.div`
  width: 70%;
  @media (max-width: 660px) {
    width: 100%;
  }
`;
const ProgressContainer = styled.div`
  @media (max-width: 660px) {
    flex-direction: column;
  }
`;
const ProgressIcon = styled.div`
  width: 100%;
  margin-bottom: 20px;
  margin-left: 10px;
`;
const BigInputWrapper = styled.div`
  max-width: 50%;
  @media (max-width: 990px) {
    max-width: 100%;
  }
`;
const MeterWrapper = styled.div`
  margin-top: 20px;
  @media (max-width: 660px) {
    flex-direction: column;
  }
`;
const AddressHeaderWrapper = styled.div`
  margin-bottom: 20px;
  @media (max-width: 660px) {
    margin-bottom: 20px;
  }
`;
const AddressWrapper = styled.div`
  margin-top: 5px;
  @media (max-width: 660px) {
    flex-direction: column;
  }
`;
const WrapperDiv = styled.div`
  width: 100%;
  position: relative;
`;
const InputStyle = styled.input`
  width: 100%;
  height: 52px;
  background-color: #fff;
  border: ${(props) => (props.error ? "2px solid #FF1500" : "2px solid #eaeeee")};
  outline: none;
  padding: 0 130px 0 20px;
  font-size: 1.1rem;
  border-radius: 0.7rem;
  position: relative;
`;
const UnitStyle = styled.div`
  width: 120px;
  height: calc(100% - 4px);
  padding: 0 30px;
  margin: 2px;
  background-color: #ced4da;
  border-radius: 0.5rem;
  position: absolute;
  top: 0;
  right: 0;
`;
