import * as React from "react";

function SvgComponent(props) {
  return (
    <svg data-name="Group 1443" xmlns="http://www.w3.org/2000/svg" width={27.563} height={24.766} viewBox="0 0 27.563 24.766" {...props}>
      <g data-name="Group 1315">
        <g data-name="Group 1313">
          <path
            data-name="Path 1276"
            d="M5.877 4.594V1.071A1.071 1.071 0 016.948 0a1.071 1.071 0 011.071 1.071v3.523a1.072 1.072 0 01-1.071 1.071 1.071 1.071 0 01-1.071-1.071z"
            fill="#404a4f"
          />
        </g>
        <g data-name="Group 1314">
          <path
            data-name="Path 1277"
            d="M19.544 4.594V1.071A1.071 1.071 0 0120.615 0a1.071 1.071 0 011.071 1.071v3.523a1.071 1.071 0 01-1.071 1.071 1.071 1.071 0 01-1.071-1.071z"
            fill="#404a4f"
          />
        </g>
      </g>
      <g data-name="Group 1317">
        <g data-name="Group 1316">
          <path
            data-name="Path 1278"
            d="M3.631 24.766A3.637 3.637 0 010 21.136V5.72a3.637 3.637 0 013.631-3.631h20.3a3.637 3.637 0 013.631 3.631v15.416a3.637 3.637 0 01-3.631 3.63h-20.3zM2.142 5.72v15.416a1.505 1.505 0 001.489 1.489h20.3a1.506 1.506 0 001.49-1.489V5.72a1.5 1.5 0 00-1.49-1.489h-20.3A1.5 1.5 0 002.142 5.72z"
            fill="#404a4f"
          />
        </g>
      </g>
      <g data-name="Group 1318">
        <path
          data-name="Path 1279"
          d="M1.195 9.719A1.071 1.071 0 01.124 8.648a1.071 1.071 0 011.071-1.071h25.173a1.071 1.071 0 011.07 1.071 1.071 1.071 0 01-1.07 1.071H1.195z"
          fill="#404a4f"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
