import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
// Components
import VerticalNavbar from "../Nav/VerticalNavbar";
// Assets
import Logo from "../../assets/svg/logo";
import LogoBig from "../../assets/svg/logoBig";

const SidebarDesktop = () => {
  const sidebarOpen = useSelector((state) => state.preference.sidebarOpen);

  return (
    <WrapperDiv className="flexColumn animate" sidebarOpen={sidebarOpen}>
      <LogoWrapper className="flexCenter">{sidebarOpen ? <LogoBig width={97} height={40.031} /> : <Logo />}</LogoWrapper>
      <NavWrapper>
        <VerticalNavbar />
      </NavWrapper>
    </WrapperDiv>
  );
};

export default SidebarDesktop;

const WrapperDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  width: ${(props) => (props.sidebarOpen ? "220px" : "80px")};
  height: 100vh;
  background-color: #404A4F;
  overflow-y: auto;
  overflow-x: hidden;
`;
const LogoWrapper = styled.div`
  height: 70px;
  width: 100%;
`;
const NavWrapper = styled.div`
  padding-top: 30px;
`;