import React from "react";
import styled from "styled-components";

const SelectButton = ({ account, id, index, address, action, active }) => {
  return (
    <Wrapper active={active} className="flexSpaceCenter pointer animate" onClick={action ? () => action(id) : null}>
      <NumberWrapper>
        <NumberCircle className="orangeColorBg flexCenter">
          <p className="font18 bold whiteColor">{index ? index : null}</p>
        </NumberCircle>
      </NumberWrapper>
      <InfoWrapper className="flexSpaceCenter">
        <InfoInnerWrapper className="flexNullCenter">
          <div className="textLeft" style={{ minWidth: "90px" }}>
            <h3 className="font18 bold">
                {account ? "Konto" : "Adresse"} {index ? index : null}:
            </h3>
          </div>
          <AddressP className="textLeft relative">
            <p className="font15">{address ? address : null}</p>
          </AddressP>
        </InfoInnerWrapper>
        <SelectP className="font13 greyColor">{active ? <em>ausgew&#228;hlt</em> : <em>zur Auswahl klicken</em>}</SelectP>
      </InfoWrapper>
    </Wrapper>
  );
};

export default SelectButton;

const Wrapper = styled.button`
  background-color: ${(props) => (props.active ? "#FFEED1" : "#fafafa")};
  min-height: 75px;
  width: 100%;
  outline: none;
  border: 0px;
  border-radius: 0.8rem;
  margin: 15px 0;
  padding: 0 15px;
  border-bottom: ${(props) => (props.active ? "5px solid #F9A81A" : "5px solid #eaeeee")};
  :hover {
    background-color: #ffeed1;
  }
  @media (max-width: 859px) {
    padding: 0px 5px;
  }
  @media (max-width: 390px) {
    flex-direction: column;
    padding: 10px 5px;
  }
`;
const NumberWrapper = styled.div`
  width: 8%;
  min-width: 70px;
`;
const InfoWrapper = styled.div`
  width: 92%;
`;
const InfoInnerWrapper = styled.div`
  width: 80%;
  @media (max-width: 859px) {
    width: 100%;
  }
  @media (max-width: 390px) {
    flex-direction: column;
  }
`;
const NumberCircle = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  @media (max-width: 390px) {
    margin-bottom: 15px;
  }
`;
const AddressP = styled.div`
  padding-left: 20px;
  @media (max-width: 859px) {
    width: 100%;
    padding-left: 0px;
  }
  @media (max-width: 390px) {
    text-align: center !important;
    padding-top: 10px;
  }
`;
const SelectP = styled.p`
  @media (max-width: 859px) {
    display: none;
  }
`;




