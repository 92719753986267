import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={41.502} height={41.194} viewBox="0 0 41.502 41.194" {...props}>
      <g data-name="Group 1388">
        <path
          data-name="Path 1254"
          d="M30.937 34.042l-2.555-8.831 5.749 2.817 4.382 9.446zm-20.372.029l-7.583 3.432 4.382-9.446 5.75-2.818zm30.846 4.975l-5.64-12.16a.981.981 0 00-.459-.469l-6.592-3.23-2.3 2.3 2.542 8.8-7.227 4.19v-8.3a.982.982 0 00-.982-.98.982.982 0 00-.982.978v8.338l-7.228-4.193 2.549-8.823-2.285-2.285-6.612 3.24a.968.968 0 00-.458.468L.097 39.08a.984.984 0 00.193 1.1.985.985 0 001.1.2l9.941-4.5 8.928 5.18a.983.983 0 00.493.132.986.986 0 00.49-.131 1 1 0 00.138-.106l8.8-5.1 9.94 4.5a.984.984 0 001.1-.2.982.982 0 00.193-1.1M20.751 1.964a9.684 9.684 0 019.672 9.672 9.637 9.637 0 01-2.879 6.88l-6.793 6.793-6.794-6.793a9.636 9.636 0 01-2.875-6.881 9.684 9.684 0 019.672-9.672m-8.182 17.944l8.179 8.179 8.177-8.178a11.59 11.59 0 003.454-8.273A11.635 11.635 0 0020.751 0 11.636 11.636 0 009.115 11.635a11.6 11.6 0 003.457 8.272M20.75 8.656a2.984 2.984 0 012.98 2.979 2.984 2.984 0 01-2.98 2.979 2.982 2.982 0 01-2.978-2.979 2.984 2.984 0 012.98-2.979m0 7.922a4.945 4.945 0 004.944-4.943 4.943 4.943 0 00-4.944-4.942 4.943 4.943 0 00-4.942 4.942 4.944 4.944 0 004.94 4.942"
          fill="#171727"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
