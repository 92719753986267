import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { closeNotification } from "../../redux/preferenceSlice";
// Components
import Backdrop from "../../components/Elements/Backdrop";
import NoNewNotification from "../../assets/svg/noNewNotification";
import NotificationBox from "../Elements/NotificationBox";
// Assets
import CloseIcon from "../../assets/svg/close";

const SidebarMobile = () => {
  const dispatch = useDispatch();
  const addrDetail = useSelector((state) => state.user.addrDetail);
  const sidebarStatus = useSelector((state) => state.preference.notificationOpen);
  return (
    <>
      {sidebarStatus ? <Backdrop action={() => dispatch(closeNotification())}></Backdrop> : null}
      <WrapperDiv className="animate" sidebarStatus={sidebarStatus}>
        <Header className="flexSpaceCenter relative" style={{ width: "100%" }}>
          <CloseButton className="pointer" onClick={() => dispatch(closeNotification())}>
            <CloseIcon color="#171727" />
          </CloseButton>
          <ClearAllBtn className="font15 animate pointer bold">Clear All</ClearAllBtn>
        </Header>
        <div className="flexCenter flexColumn" style={{padding: '0 10px', overflowY: "auto", maxHeight: "92vh"}}>
          {addrDetail && addrDetail.dashboardState && addrDetail.dashboardState.notifications && addrDetail.dashboardState.notifications.length > 0 ? (
            addrDetail.dashboardState.notifications.map((item, index) => (
              <NotificationBox key={index} energy={item.energyType} title={item.title} date={item.createdDate} changeType={item.changeType} />
            ))
          ) : (
            <div className="flexCenter flexColumn" style={{ paddingTop: "50%" }}>
              <NoNewNotification />
              <p className="font15" style={{ color: "#ECECEC", paddingTop: "30px" }}>
                No New Notification
              </p>
            </div>
          )}
        </div>
      </WrapperDiv>
    </>
  );
};

export default SidebarMobile;

const WrapperDiv = styled.div`
  background-color: #fff;
  width: 70%;
  max-width: 320px;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
  right: ${(props) => (props.sidebarStatus ? "0" : "-70%")};
`;
const Header = styled.div`
  width: 100%;
  height: 60px;
`;
const CloseButton = styled.button`
  padding: 0 20px;
  max-height: 60px;
  outline: none;
  border: 0px;
  background-color: transparent;
`;
const ClearAllBtn = styled.button`
  background-color: transparent;
  outline: none;
  border: 0px;
  padding: 10px 15px;
  color: #f9a81a;
  :hover {
    color: #171727;
  }
`;