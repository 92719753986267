import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={135} height={135} {...props}>
      <g data-name="Group 2044" transform="translate(-893 -471)">
        <circle data-name="Ellipse 68" cx={67.5} cy={67.5} r={67.5} transform="translate(893 471)" fill="#f3f3f3" opacity={0.3} />
        <g data-name="Group 678">
          <path data-name="Path 851" d="M979.57 531.828h-38l-4.048 9.583h46.1z" fill="#ffe4b4" />
        </g>
        <g data-name="Group 680">
          <g data-name="Group 679">
            <path
              data-name="Path 852"
              d="M933.18 569.084a4.3 4.3 0 01-4.295-4.294v-19.33a1 1 0 01.288-.7 1 1 0 01.7-.293h21.689a1 1 0 01.7.293.987.987 0 01.29.7v.105a8.023 8.023 0 008.015 7.989 8.021 8.021 0 008.013-7.992v-.1a1 1 0 01.29-.7.988.988 0 01.7-.293h21.69a1 1 0 01.7.293.987.987 0 01.291.7v19.33a4.3 4.3 0 01-4.3 4.294h-54.773zm-2.312-4.294a2.331 2.331 0 002.313 2.313h54.773a2.334 2.334 0 002.313-2.313v-18.34h-19.741a10 10 0 01-9.956 9.087 10 10 0 01-9.957-9.087h-19.742v18.34zm38.7-18.34z"
              fill="#f9a81a"
            />
          </g>
        </g>
        <g data-name="Group 682">
          <g data-name="Group 681">
            <path
              data-name="Path 853"
              d="M929.41 546.333a.989.989 0 01-.408-1.34l9.5-17.846a.983.983 0 01.874-.524h42.382a.988.988 0 01.874.524l9.5 17.846a.993.993 0 01-.41 1.34.993.993 0 01-1.342-.412l-9.22-17.316h-41.191l-9.22 17.319a.993.993 0 01-.877.524.989.989 0 01-.464-.116z"
              fill="#f9a81a"
            />
          </g>
        </g>
        <g data-name="Group 687">
          <g data-name="Group 686">
            <g data-name="Group 683">
              <path
                data-name="Path 854"
                d="M959.576 517.45v-8.426a.989.989 0 01.988-.991.99.99 0 01.992.991v8.426a.992.992 0 01-.992.993.991.991 0 01-.988-.993z"
                fill="#f9a81a"
              />
            </g>
            <g data-name="Group 684">
              <path
                data-name="Path 855"
                d="M974.194 521.629a.994.994 0 010-1.4l5.961-5.958a.989.989 0 011.4 0 1 1 0 010 1.4l-5.959 5.959a.993.993 0 01-.7.288.993.993 0 01-.7-.288z"
                fill="#f9a81a"
              />
            </g>
            <g data-name="Group 685">
              <path
                data-name="Path 856"
                d="M945.538 521.628l-5.961-5.958a.993.993 0 010-1.4.992.992 0 011.4 0l5.961 5.957a.994.994 0 010 1.4.992.992 0 01-.7.29.988.988 0 01-.7-.29z"
                fill="#f9a81a"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
