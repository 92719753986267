import { configureStore } from "@reduxjs/toolkit";
// Slices
import { userSlice } from "./userSlice";
import preferenceSlice from "./preferenceSlice";

export const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    preference: preferenceSlice,
  },
});
