import React from "react";
import styled from "styled-components";
// Assets
import CheckMarkIcon from "../../assets/svg/checkmark";


const ProgressPoint = ({ active, current, unactive, lineLeft, lineRight, lineLeftActive, lineRightActive, title }) => {
  return (
    <Wrapper className="textCenter">
      <CircleWrapper active={active} current={current} unactive={unactive} className="flexCenter">
        {active ? <CheckMarkIcon /> : null}
      </CircleWrapper>
      <ProgressLine className="flexNullCenter">
        <ProgressLineInner1 lineLeft={lineLeft} lineLeftActive={lineLeftActive}></ProgressLineInner1>
        <ProgressLineInner2 lineRight={lineRight} lineRightActive={lineRightActive}></ProgressLineInner2>
      </ProgressLine>
      <p className="font11" style={{ padding: "0 5px" }}>
        {title}
      </p>
    </Wrapper>
  );
};

export default ProgressPoint;

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;
const CircleWrapper = styled.div`
  margin: 0 auto 5px auto;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: ${(props) => (props.active ? "#f9a81a" : "#fff")};
  border: ${(props) => (props.unactive ? "4px solid #EAEEEE" : props.current ? "6px solid #171727" : null)};
  position: relative;
  z-index: 9;
`;
const ProgressLine = styled.div`
  width: 100%;
  height: 4px;
  position: absolute;
  top: 10px;
`;
const ProgressLineInner1 = styled.div`
  width: 50%;
  height: 4px;
  background-color: ${(props) => (props.lineLeftActive ? "#F9A81A" : props.lineLeft ? "#EAEEEE" : null)};
`;
const ProgressLineInner2 = styled.div`
  width: 50%;
  height: 4px;
  background-color: ${(props) => (props.lineRightActive ? "#F9A81A" : props.lineRight ? "#EAEEEE" : null)};
`;
