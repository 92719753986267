import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={41.252} height={36.631} viewBox="0 0 41.252 36.631" {...props}>
      <g data-name="Group 1499">
        <g data-name="Group 1493">
          <g data-name="Group 1490">
            <g data-name="Group 1489">
              <path
                data-name="Path 1340"
                d="M0 29.637a1.7 1.7 0 011.7-1.7h6.865a6.582 6.582 0 002.91-.838 6.579 6.579 0 002.325-1.942L24.407 9.479a10.184 10.184 0 013.369-3.018 8.833 8.833 0 014.229-1.262h3.8a1.7 1.7 0 011.7 1.7 1.7 1.7 0 01-1.7 1.7h-3.8a5.56 5.56 0 00-2.54.813 6.869 6.869 0 00-2.235 1.967L16.623 27.058a9.913 9.913 0 01-3.549 3.043 9.909 9.909 0 01-4.51 1.235H1.699a1.7 1.7 0 01-1.7-1.7z"
              />
            </g>
          </g>
          <g data-name="Group 1491">
            <path
              data-name="Path 1341"
              d="M38.343 8.197L33.055 2.91a1.706 1.706 0 010-2.411 1.706 1.706 0 012.411 0l5.288 5.289a1.7 1.7 0 010 2.409 1.7 1.7 0 01-1.2.5 1.7 1.7 0 01-1.206-.5z"
            />
          </g>
          <g data-name="Group 1492">
            <path
              data-name="Path 1342"
              d="M33.055 13.484a1.7 1.7 0 010-2.411l5.288-5.286a1.7 1.7 0 012.41 0 1.7 1.7 0 010 2.411l-5.288 5.286a1.7 1.7 0 01-1.2.5 1.7 1.7 0 01-1.2-.5z"
            />
          </g>
        </g>
        <g data-name="Group 1498">
          <g data-name="Group 1495">
            <g data-name="Group 1494">
              <path
                data-name="Path 1343"
                d="M32.01 31.436a8.834 8.834 0 01-4.228-1.261 10.2 10.2 0 01-3.369-3.018L13.806 11.476a6.613 6.613 0 00-2.325-1.941 6.6 6.6 0 00-2.912-.837H1.705a1.7 1.7 0 01-1.7-1.7 1.7 1.7 0 011.7-1.705h6.864a9.9 9.9 0 014.51 1.234 9.92 9.92 0 013.549 3.043l10.607 15.68a6.875 6.875 0 002.235 1.968 5.573 5.573 0 002.54.812h3.8a1.7 1.7 0 011.7 1.7 1.7 1.7 0 01-1.7 1.7h-3.8z"
              />
            </g>
          </g>
          <g data-name="Group 1496">
            <path
              data-name="Path 1344"
              d="M33.056 36.132a1.7 1.7 0 010-2.41l5.288-5.288a1.706 1.706 0 012.411 0 1.7 1.7 0 010 2.41l-5.288 5.288a1.7 1.7 0 01-1.2.5 1.7 1.7 0 01-1.205-.5z"
            />
          </g>
          <g data-name="Group 1497">
            <path
              data-name="Path 1345"
              d="M38.343 30.844l-5.288-5.286a1.705 1.705 0 010-2.411 1.705 1.705 0 012.41 0l5.288 5.288a1.7 1.7 0 010 2.41 1.7 1.7 0 01-1.205.5 1.7 1.7 0 01-1.2-.5z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
