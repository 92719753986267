import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { togglePopup } from "../../redux/userSlice";
// Components
import NavbarTop from "./NavbarTop";
import NavbarTopDesktop from "./NavbarTopDesktop";
import SidebarDesktop from "./SidebarDesktop";
import SidebarMobile from "./SidebarMobile";
import Popup from "../Elements/Popup";
import NotificationSidebar from "../Nav/NotificationSidebar";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const Navbar = () => {
  const dispatch = useDispatch();
  const popup = useSelector((state) => state.user.popup);
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {popup ? <Popup actionSecond={() => dispatch(togglePopup(false))} /> : null}
      {windowDimensions.width < 860 ? (
        <>
          <NavbarTop />
          <SidebarMobile />
          <NotificationSidebar />
        </>
      ) : (
        <>
          <SidebarDesktop />
          <NavbarTopDesktop />
        </>
      )}
    </>
  );
};

export default Navbar;

