import React, { useEffect } from "react";
import { Route } from "react-router";
import { Switch } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
// API
import AuthorizeRoute from "./components/api-authorization/AuthorizeRoute";
import ApiAuthorizationRoutes from "./components/api-authorization/ApiAuthorizationRoutes";
import { ApplicationPaths } from "./components/api-authorization/ApiAuthorizationConstants";
import { getToken, getAccounts, getAccountDetail, selectAddress, getAddressDetail } from "./redux/userSlice";
// Components
import Spinner from "./components/Elements/Spinner.jsx";
import AccountSelect from "./pages/AccountSelect";
// Pages
import Dashboard from "./pages/Dashboard.jsx";
import Strom from "./pages/Strom.jsx";
import Gas from "./pages/Gas.jsx";
import Error404 from "./pages/Error404.jsx";
// Style
import { GlobalStyles } from "./style/GlobalStyles";

function App() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (!user.token) {
      dispatch(getToken());
    }
    if (user.token) {
      dispatch(getAccounts(user.token));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.token]);

  useEffect(() => {
    if (user.token && user.selectedAccount) {
      const token = user.token;
      const id = user.selectedAccount;
      dispatch(getAccountDetail({token, id}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.selectedAccount]);

  useEffect(() => {
    if (user.accDetail && !user.selectedAddress) {
      dispatch(selectAddress(user.accDetail.addresses[0]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.accDetail]);

    useEffect(() => {
      if (user.selectedAccount && user.selectedAddress) {
        const token = user.token;
        const accId = user.selectedAccount;
        const addId = user.selectedAddress.id;
        dispatch(getAddressDetail({ token, accId, addId })); // (0010Q000014OLHiQAO, a050Q0000059updQAA)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.selectedAddress]);

  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap" rel="stylesheet" />
      </Helmet>
      {user.isFetching ? <Spinner transparent /> : null}
      {user.token && user.accounts && !user.selectedAccount ? <AccountSelect /> : null}
      <GlobalStyles />
      <Switch>
        <AuthorizeRoute exact path="/" component={Dashboard} />
        <AuthorizeRoute exact path="/strom" component={Strom} />
        <AuthorizeRoute exact path="/gas" component={Gas} />
        <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
        <Route path="*">
          <Error404 />
        </Route>
      </Switch>
    </>
  );
}

export default App;
