import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={44.7} height={46.601} viewBox="0 0 44.7 46.601" {...props}>
      <path
        data-name="Path 1323"
        d="M26.418 42.885s-3.447 5.829-7.523.029c-1.635-.267-2.615-1.427-2.407-5.974v-2.257l-3.741-3.569S2.256 15.334 20.92 9.45c2.319-.179 18.249.832 13.434 18.249-1.545 2.259-5.885 7.193-5.885 7.193v2.169l.06 4.815z"
        fill="#fff"
      />
      <g data-name="Group 1488">
        <g data-name="Group 1467">
          <path
            data-name="Path 1324"
            d="M18.207 31.851a.9.9 0 01-.4-1.1l2.163-5.8-1.576-.011.007-.91.707.59-.707-.59-.007.91a.922.922 0 01-.835-.532.9.9 0 01.136-.97l7.992-9.263a.937.937 0 011.187-.188.9.9 0 01.377 1.122l-2.396 5.778h1.813a.929.929 0 01.841.529.9.9 0 01-.136.973l-7.993 9.263a.928.928 0 01-.706.321.923.923 0 01-.467-.123zm3.093-8.712a.931.931 0 01.754.391.9.9 0 01.107.833l-.823 2.2 3.325-3.853H23.48a.931.931 0 01-.771-.4.892.892 0 01-.088-.852l.727-1.754-2.959 3.428.912.008z"
            fill="#ff6700"
          />
        </g>
        <g data-name="Group 1472">
          <g data-name="Group 1468">
            <path
              data-name="Path 1325"
              d="M17.976 39.55a.909.909 0 01.7-1.084l7.413-1.6a.929.929 0 011.1.7.915.915 0 01-.707 1.087l-7.406 1.591a.891.891 0 01-.2.021.917.917 0 01-.9-.715z"
              fill="#3c4256"
            />
          </g>
          <g data-name="Group 1469">
            <path
              data-name="Path 1326"
              d="M17.927 42.846a.911.911 0 01.7-1.09.927.927 0 011.105.691 3.02 3.02 0 002.957 2.329 3.021 3.021 0 002.965-2.353.922.922 0 011.1-.7.911.911 0 01.709 1.081 4.866 4.866 0 01-4.775 3.8 4.865 4.865 0 01-4.763-3.755z"
              fill="#3c4256"
            />
          </g>
          <g data-name="Group 1471">
            <g data-name="Group 1470">
              <path
                data-name="Path 1327"
                d="M17.68 43.404a2.092 2.092 0 01-2.106-2.075V36.85a.917.917 0 01.924-.91.917.917 0 01.925.91v4.48a.255.255 0 00.254.252h9.707a.256.256 0 00.256-.252v-4.48a.919.919 0 01.927-.91.919.919 0 01.927.91v4.48a2.094 2.094 0 01-2.109 2.075H17.68z"
                fill="#3c4256"
              />
            </g>
          </g>
        </g>
        <g data-name="Group 1473">
          <path
            data-name="Path 1328"
            d="M16.992 35.836a1.943 1.943 0 01-1.519-.768l-1.768-2.421A13.629 13.629 0 018.573 22.01 13.851 13.851 0 0122.534 8.274 13.849 13.849 0 0136.493 22.01a13.634 13.634 0 01-5.134 10.64l-1.765 2.419a1.946 1.946 0 01-1.52.768H16.992zm.034-1.82zm11.022 0h.009a.47.47 0 00.054-.029l1.821-2.5.173-.179a11.824 11.824 0 004.537-9.3 12.029 12.029 0 00-12.107-11.913 12.026 12.026 0 00-12.106 11.916 11.81 11.81 0 004.539 9.3l.17.179 1.821 2.5h.009a.208.208 0 00.055.027h11.025z"
            fill="#3c4256"
          />
        </g>
        <g data-name="Group 1487">
          <g data-name="Group 1480">
            <g data-name="Group 1474">
              <path
                data-name="Path 1329"
                d="M.927 22.909A.92.92 0 010 21.998a.919.919 0 01.927-.91h4.029a.919.919 0 01.927.91.921.921 0 01-.927.911H.927z"
                fill="#3c4256"
              />
            </g>
            <g data-name="Group 1475">
              <path
                data-name="Path 1330"
                d="M39.746 22.908a.92.92 0 01-.926-.911.919.919 0 01.926-.909h4.028a.918.918 0 01.925.909.919.919 0 01-.925.911h-4.028z"
                fill="#3c4256"
              />
            </g>
            <g data-name="Group 1476">
              <path
                data-name="Path 1331"
                d="M33.997 10.537a.9.9 0 010-1.291l2.847-2.8a.935.935 0 011.31 0 .9.9 0 010 1.288l-2.847 2.8a.934.934 0 01-.654.266.937.937 0 01-.655-.265z"
                fill="#3c4256"
              />
            </g>
            <g data-name="Group 1477">
              <path
                data-name="Path 1332"
                d="M21.424 4.875V.911A.918.918 0 0122.35 0a.918.918 0 01.926.911v3.964a.919.919 0 01-.926.912.92.92 0 01-.926-.912z"
                fill="#3c4256"
              />
            </g>
            <g data-name="Group 1478">
              <path
                data-name="Path 1333"
                d="M9.394 10.536l-2.848-2.8a.9.9 0 010-1.29.94.94 0 011.311 0l2.847 2.8a.9.9 0 010 1.29.929.929 0 01-.654.267.933.933 0 01-.655-.266z"
                fill="#3c4256"
              />
            </g>
            <g data-name="Group 1479">
              <path
                data-name="Path 1334"
                d="M9.394 10.536l-2.848-2.8a.9.9 0 010-1.29.94.94 0 011.311 0l2.847 2.8a.9.9 0 010 1.29.929.929 0 01-.654.267.933.933 0 01-.655-.266z"
                fill="#3c4256"
              />
            </g>
          </g>
          <g data-name="Group 1486">
            <g data-name="Group 1481">
              <path
                data-name="Path 1335"
                d="M5.208 15.568l-1.912-.828a.906.906 0 01-.474-1.2.931.931 0 011.219-.468l1.913.827a.9.9 0 01.474 1.2.923.923 0 01-.845.545.944.944 0 01-.375-.077z"
                fill="#3c4256"
              />
            </g>
            <g data-name="Group 1482">
              <path
                data-name="Path 1336"
                d="M38.558 15.796a.9.9 0 01.527-1.178l1.953-.749a.932.932 0 011.2.519.906.906 0 01-.528 1.179l-1.953.748a.957.957 0 01-.336.062.919.919 0 01-.863-.58z"
                fill="#3c4256"
              />
            </g>
            <g data-name="Group 1483">
              <path
                data-name="Path 1337"
                d="M29.356 6.331a.909.909 0 01-.474-1.2l.845-1.886a.931.931 0 011.219-.466.9.9 0 01.476 1.2l-.846 1.881a.927.927 0 01-.849.544.95.95 0 01-.371-.077z"
                fill="#3c4256"
              />
            </g>
            <g data-name="Group 1485">
              <path
                data-name="Path 1339"
                d="M14.857 5.542l-.766-1.937a.909.909 0 01.528-1.181.93.93 0 011.2.519l.765 1.935a.911.911 0 01-.528 1.18.949.949 0 01-.337.062.924.924 0 01-.863-.581z"
                fill="#3c4256"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
