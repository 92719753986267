import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={21.845} height={24.527} viewBox="0 0 21.845 24.527" {...props}>
      <g data-name="Group 1541">
        <g data-name="Group 1534">
          <path
            data-name="Path 1359"
            d="M9.485 24.234a.991.991 0 01-.283-.82l.4-3.328a1 1 0 01.284-.585l6.5-6.5a1 1 0 011.406 0l2.942 2.942a1 1 0 01.291.7 1 1 0 01-.291.7l-6.5 6.5a.992.992 0 01-.588.284l-3.339.387a1.017 1.017 0 01-.115.007.992.992 0 01-.7-.292zm2.047-3.57l-.207 1.737 1.744-.2 5.557-5.557-1.541-1.538-5.558 5.558z"
            fill="#fff"
          />
        </g>
        <g data-name="Group 1535">
          <path
            data-name="Path 1360"
            d="M19.323 17.343l-2.941-2.94a1 1 0 01-.291-.711 1 1 0 01.3-.706 3.8 3.8 0 012.606-1.105 2.838 2.838 0 012.032.813 2.838 2.838 0 01.812 2.031 3.8 3.8 0 01-1.1 2.606 1 1 0 01-.706.3h-.008a1 1 0 01-.7-.292zm.7-.7l.7-.7-.7.7zm-.2-1.609a1.361 1.361 0 00.035-.307.854.854 0 00-.229-.626.854.854 0 00-.627-.231 1.334 1.334 0 00-.308.035l1.129 1.129z"
            fill="#fff"
          />
        </g>
        <g data-name="Group 1537">
          <g data-name="Group 1536">
            <path
              data-name="Path 1361"
              d="M3.48 23.774a3.484 3.484 0 01-3.478-3.478V3.479A3.485 3.485 0 013.48 0h12.039a3.485 3.485 0 013.479 3.478v5.558a.994.994 0 01-.994.994.994.994 0 01-.994-.994V3.478a1.506 1.506 0 00-1.491-1.491H3.48a1.506 1.506 0 00-1.491 1.491v16.817a1.506 1.506 0 001.491 1.492h2.754a.993.993 0 01.994.994.994.994 0 01-.994.994H3.48z"
              fill="#fff"
            />
          </g>
        </g>
        <g data-name="Group 1538">
          <path
            data-name="Path 1362"
            d="M4.696 7.596a.994.994 0 01-.994-.994.994.994 0 01.994-.994h9.275a.994.994 0 01.994.994.994.994 0 01-.994.994H4.696z"
            fill="#fff"
          />
        </g>
        <g data-name="Group 1539">
          <path
            data-name="Path 1363"
            d="M4.537 11.325a.994.994 0 01-.994-.994.993.993 0 01.994-.994h9.337a.993.993 0 01.994.994.994.994 0 01-.994.994H4.537z"
            fill="#fff"
          />
        </g>
        <g data-name="Group 1540">
          <path
            data-name="Path 1364"
            d="M4.442 14.992a.994.994 0 01-.994-.994.994.994 0 01.994-.994h5.731a.994.994 0 01.994.994.994.994 0 01-.994.994H4.442z"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
