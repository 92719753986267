import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={75.471} height={67.669} viewBox="0 0 75.471 67.669" {...props}>
      <g data-name="Group 1436">
        <g data-name="Group 1435">
          <g data-name="Group 1434">
            <path
              data-name="Path 1319"
              d="M33.834 67.666a33.737 33.737 0 01-23.924-9.91A33.737 33.737 0 010 33.831 33.733 33.733 0 019.91 9.907a33.738 33.738 0 0123.925-9.91 33.737 33.737 0 0123.924 9.91 33.734 33.734 0 019.91 23.924 33.741 33.741 0 01-9.91 23.925 33.735 33.735 0 01-23.918 9.91h-.007zM12.2 12.196a30.492 30.492 0 00-8.962 21.635A30.494 30.494 0 0012.2 55.466a30.491 30.491 0 0021.634 8.961 30.492 30.492 0 0021.635-8.961 30.5 30.5 0 008.962-21.635 30.494 30.494 0 00-8.962-21.635 30.494 30.494 0 00-21.635-8.961 30.492 30.492 0 00-21.635 8.961z"
              fill="#ececec"
            />
          </g>
          <g data-name="Group 957">
            <g data-name="Group 814">
              <g data-name="Path 995">
                <path
                  data-name="Path 1320"
                  d="M20.931 38.642a1.354 1.354 0 01-.66-.784 1.342 1.342 0 01.089-1.021l5.864-11.3.007-.012a10.872 10.872 0 0114.66-4.64 10.874 10.874 0 014.64 14.661l-.008.016v.009l-5.859 11.283a1.335 1.335 0 01-.783.661 1.328 1.328 0 01-1.021-.089l-16.925-8.787zm2.42-1.758L37.9 44.438l5.223-10.059.02-.038.013-.024a8.209 8.209 0 00-3.5-11.052 8.207 8.207 0 00-11.046 3.487l-.018.036-.02.04-5.222 10.058z"
                  fill="#ececec"
                />
              </g>
            </g>
            <g data-name="Group 815">
              <g data-name="Path 996">
                <path
                  data-name="Path 1321"
                  d="M18.163 37.212a1.338 1.338 0 01-.571-1.8 1.339 1.339 0 011.8-.573l22.456 11.659a1.34 1.34 0 01.572 1.806 1.34 1.34 0 01-1.8.57z"
                  fill="#ececec"
                />
              </g>
            </g>
            <g data-name="Group 816">
              <g data-name="Path 997">
                <path
                  data-name="Path 1322"
                  d="M24.48 40.313a1.339 1.339 0 011.8-.57 1.338 1.338 0 01.572 1.8 3.1 3.1 0 001.32 4.176 3.1 3.1 0 004.177-1.322 1.338 1.338 0 011.8-.571 1.338 1.338 0 01.572 1.8 5.771 5.771 0 01-7.782 2.463 5.773 5.773 0 01-2.462-7.782z"
                  fill="#ececec"
                />
              </g>
            </g>
          </g>
        </g>
        <g data-name="Ellipse 104" transform="translate(49.471 3.999)" fill="#a5e255" stroke="#fff" strokeWidth={2}>
          <circle cx={13} cy={13} r={13} stroke="none" />
          <circle cx={13} cy={13} r={12} fill="none" />
        </g>
        <text data-name={0} transform="translate(59.471 20.999)" fill="#fff" fontSize={11} fontFamily="NotoSans-Bold, Noto Sans" fontWeight={700}>
          <tspan x={0} y={0}>
            {"0"}
          </tspan>
        </text>
      </g>
    </svg>
  );
}

export default SvgComponent;
