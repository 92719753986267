import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={42} height={41.999} viewBox="0 0 42 41.999" {...props}>
      <defs>
        <clipPath id="prefix__a">
          <path data-name="Path 1269" d="M0-132.333h42v42H0z" transform="translate(0 132.333)" fill="none" />
        </clipPath>
      </defs>
      <g data-name="Group 1300">
        <g data-name="Group 1303" clipPath="url(#prefix__a)">
          <g data-name="Group 1300">
            <path
              data-name="Path 1266"
              d="M38.704 21.053a17.694 17.694 0 01-17.693 17.693A17.694 17.694 0 013.317 21.053 17.694 17.694 0 0121.011 3.359a17.694 17.694 0 0117.693 17.694"
              fill="#fff"
            />
          </g>
          <g data-name="Group 1301">
            <path
              data-name="Path 1267"
              d="M11.088 11.088a13.961 13.961 0 00-4.106 9.911 13.962 13.962 0 004.106 9.912 13.956 13.956 0 009.913 4.1 13.95 13.95 0 009.911-4.1A13.95 13.95 0 0035.017 21a13.949 13.949 0 00-4.105-9.912 13.951 13.951 0 00-9.911-4.106 13.956 13.956 0 00-9.913 4.106M0 20.999a21 21 0 0121-21 21 21 0 0121 21 21 21 0 01-21 21 21 21 0 01-21-21"
              fill="#f3a600"
            />
          </g>
          <g data-name="Group 1302">
            <path data-name="Path 1268" d="M23.608 12.073l-8.215 9.817h3.82l-1.52 8.749 8.913-10.516h-4.928z" fill="#41494f" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
