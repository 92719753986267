import React from "react";
import styled from "styled-components";
import { NavLink, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { closeMobile } from "../../redux/preferenceSlice";
import { togglePopup } from "../../redux/userSlice";
// Assets
import DashboardIcon from "../../assets/svg/nav/dashboard";
import ElectricIcon from "../../assets/svg/nav/electric";
import GasIcon from "../../assets/svg/nav/gas";
import LogoutIcon from "../../assets/svg/nav/logoutIcon";

const TabBar = ({ icon, path, title, full, withPopup }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const sidebarOpen = useSelector((state) => state.preference.sidebarOpen);

  let menuIcon = <div></div>;

  if (icon === "dashboard") {
    menuIcon = <DashboardIcon color={location.pathname === path ? "#F9A81A" : "#fff"} />;
  } else if (icon === "strom") {
    menuIcon = <ElectricIcon color={location.pathname === path ? "#F9A81A" : "#fff"} />;
  } else if (icon === "gas") {
    menuIcon = <GasIcon color={location.pathname === path ? "#F9A81A" : "#fff"} />;
  } else if (icon === "logout") {
    menuIcon = <LogoutIcon />;
  } else {
    menuIcon = <DashboardIcon color={location.pathname === path ? "#F9A81A" : "#fff"} />;
  }

  const logoutHandler = () => {
    dispatch(closeMobile());
    dispatch(togglePopup(true));
  }

  if(withPopup) {
    return (
      <StyledButton className="flexNullCenter animate pointer" onClick={() => logoutHandler()}>
        <IconWrapper className="flexCenter">{menuIcon}</IconWrapper>
        {sidebarOpen || full ? <LinkText className="font15 medium">{title}</LinkText> : null}
      </StyledButton>
    );
  }else {
    return (
    <StyledLink
      to={path}
      exact
      className="flexNullCenter animate"
      activeStyle={{ backgroundColor: "#374146", borderRight: "5px solid #F9A81A" }}
      onClick={() => dispatch(closeMobile())}
    >
      <IconWrapper className="flexCenter">{menuIcon}</IconWrapper>
      {sidebarOpen || full ? <LinkText className="font15 medium">{title}</LinkText> : null}
    </StyledLink>
  );
  }
};

export default TabBar;
const StyledButton = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  width: 100%;
  margin: 5px auto;
  border-right: "5px solid #404A4F";
  :hover {
    background-color: #374146;
  }
`;
const StyledLink = styled(NavLink)`
  border: 0px;
  outline: none;
  background-color: transparent;
  width: 100%;
  margin: 5px auto;
  border-right: "5px solid #404A4F";
  :hover {
    background-color: #374146;
  }
`;
const IconWrapper = styled.div`
  width: 80px;
  height: 70px;
  border-radius: 5px;
`;
const LinkText = styled.p`
  color: #fff;
`;
