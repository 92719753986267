import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { closeMobile } from "../../redux/preferenceSlice";
// Components
import Backdrop from "../Elements/Backdrop";
import VerticalNavbar from "../Nav/VerticalNavbar";
// Assets
import Large from "../../assets/svg/logoBig";

const SidebarMobile = () => {
  const dispatch = useDispatch();
  const sidebarStatus = useSelector((state) => state.preference.mobileOpen);
  return (
    <>
      {sidebarStatus ? <Backdrop action={() => dispatch(closeMobile())}></Backdrop> : null}
      <WrapperDiv className="animate" sidebarStatus={sidebarStatus}>
        <HeaderDiv className="flexCenter">
          <Large width={97} height={40} />
        </HeaderDiv>
        <BodyDiv className="textCenter">
          <VerticalNavbar full />
        </BodyDiv>
      </WrapperDiv>
    </>
  );
};

export default SidebarMobile;

const WrapperDiv = styled.div`
  background-color: #404a4f;
  width: 80%;
  max-width: 220px;
  height: 100vh;
  position: fixed;
  overflow-y: auto;
  top: 0;
  z-index: 9999;
  left: ${(props) => (props.sidebarStatus ? "0" : "-80%")};
`;
// HEADER
const HeaderDiv = styled.div`
  width: 100%;
  height: 60px;
`;
// BODY
const BodyDiv = styled.div`
  width: 100%;
  margin: 3vh 0;
`;
