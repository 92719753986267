import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={26.158} height={18.475} viewBox="0 0 26.158 18.475" {...props}>
      <g data-name="Group 1442">
        <g data-name="Group 1342">
          <g data-name="Group 1320">
            <path
              data-name="Path 1280"
              d="M.897 18.475v-.9.9a.9.9 0 01-.634-.263.9.9 0 01-.263-.634V.897A.9.9 0 01.263.263.9.9 0 01.897 0h24.364a.9.9 0 01.634.263.9.9 0 01.263.634v16.681a.9.9 0 01-.263.634.9.9 0 01-.634.263H.897zm23.466-1.794V1.795H1.794v14.886z"
              fill="#404a4f"
            />
          </g>
          <g data-name="Group 1321">
            <path
              data-name="Path 1281"
              d="M12.393 11.601L.325 1.619A.9.9 0 01.205.355.9.9 0 011.469.236l11.5 9.513L24.693.231a.9.9 0 011.262.131.9.9 0 01-.131 1.263l-12.3 9.982a.9.9 0 01-.566.2.9.9 0 01-.572-.206z"
              fill="#404a4f"
            />
          </g>
          <g data-name="Group 1324">
            <g data-name="Group 1322">
              <path
                data-name="Path 1282"
                d="M23.793 17.479l-8.6-7.7a.9.9 0 01-.07-1.267.9.9 0 011.267-.07l8.6 7.7a.9.9 0 01.07 1.267.9.9 0 01-.669.3.894.894 0 01-.6-.229z"
                fill="#404a4f"
              />
            </g>
            <g data-name="Group 1323">
              <path
                data-name="Path 1283"
                d="M1.187 17.247a.9.9 0 01.055-1.267l8.384-7.682a.9.9 0 011.268.055.9.9 0 01-.055 1.267l-8.383 7.682a.893.893 0 01-.606.236.9.9 0 01-.662-.291z"
                fill="#404a4f"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
