import React from "react";
import styled from "styled-components";

const InputLabel = ({ placeholder, value, setValue, name, disabled }) => {
  return (
    <InputDiv>
      {value && value.length > 0 ? <LabelLabel className="font13 moveLabelUp">{placeholder}</LabelLabel> : null}
      <InputInput
        style={{ cursor: disabled ? "not-allowed" : "unset" }}
        disabled={disabled}
        name={name}
        className="radius6 font15"
        placeholder={placeholder}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </InputDiv>
  );
};

const InputDiv = styled.div`
  width: 100%;
  position: relative;
  min-height: 73px;
  margin-top: 10px;
`;
const InputInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  padding: 0 20px;
  min-height: 56px;
  border: 2px solid #eaeeee;
  color: #171727;
  background-color: #fff;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #d3d8d8;
  }
  :-ms-input-placeholder {
    color: #d3d8d8;
  }
  outline: none;
  &:focus {
    border: 2px solid #f9a81a;
  }
`;
const LabelLabel = styled.label`
  position: absolute;
  top: -10px;
  left: 10px;
  z-index: 10;
  background-color: #fff;
  padding: 0 10px;
  color: #f9a81a;
`;

export default InputLabel;
