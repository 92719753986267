import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={21.299} height={24.052} viewBox="0 0 21.299 24.052" {...props}>
      <g data-name="Group 1264">
        <g data-name="Group 1262">
          <path
            data-name="Path 1252"
            d="M4.018 6.343A6.343 6.343 0 0110.36 0a6.344 6.344 0 016.343 6.343 6.343 6.343 0 01-6.343 6.342 6.342 6.342 0 01-6.342-6.342zm2.472 0a3.876 3.876 0 003.87 3.869 3.875 3.875 0 003.87-3.869 3.875 3.875 0 00-3.87-3.87 3.876 3.876 0 00-3.87 3.87z"
            fill={props.color}
          />
        </g>
        <g data-name="Group 1263">
          <path
            data-name="Path 1253"
            d="M.028 23.075a1 1 0 01-.027-.267c0-.211.022-2.406.022-2.428a6.343 6.343 0 016.342-6.343h8.57a6.343 6.343 0 016.342 6.343c0 .022.022 2.263.022 2.434v.008a1.237 1.237 0 01-1.236 1.236 1.236 1.236 0 01-1.236-1.209v-.033c0-.138-.022-2.371-.022-2.434a3.876 3.876 0 00-3.87-3.87h-8.57a3.875 3.875 0 00-3.87 3.87l-.023 2.405a1.236 1.236 0 01-.977 1.245 1.223 1.223 0 01-.261.028 1.237 1.237 0 01-1.207-.977z"
            fill={props.color}
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
